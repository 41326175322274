import {AxiosResponse} from 'axios';
import {UserFeelingsStateData} from 'store/reducers/userFeelings';
import api from './api';

export type Emotion =
  | 'neutral'
  | 'excited'
  | 'nervous'
  | 'tired'
  | 'satisfied'
  | 'happy'
  | 'skipped';

export type DisplayEmotion =
  | 'Neutro'
  | 'Animado'
  | 'Nervoso'
  | 'Cansado'
  | 'Satisfeito'
  | 'Feliz'
  | 'Sem resposta';

export type DevolutiveStage =
  | 'pre-composition review'
  | 'during-composition review';

export interface UserFeelingsData {
  composition: number;
  choice: Emotion;
  stage: DevolutiveStage;
  comment?: string;
}

export const fetchUserFeelingsService = (
  compositionId: number,
): Promise<AxiosResponse<UserFeelingsStateData>> =>
  api(`/api/v1/user_feelings/${compositionId}/`);

export const createUserFeelingsService = (
  data: Partial<UserFeelingsData> = {},
): Promise<AxiosResponse<UserFeelingsData>> =>
  api(`/api/v1/user_feelings/`, {
    method: 'post',
    data,
  });
