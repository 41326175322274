import {fromJS, Map} from 'immutable';
import {AnyAction, Reducer} from 'redux';
import {call, put, StrictEffect} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {ApplicationState} from 'store/rootReducer';
import {action} from 'typesafe-actions';
import {FeatureFlags} from 'utils/types/featureFlags';
import {
  fetchFeatureFlagService,
  FlagEvaluationRequest,
} from '../../services/featureFlags';

// Action types
export enum FeatureFlagsTypes {
  FETCH_REQUEST = '@newFeatureFlags/FETCH_REQUEST',
  FETCH_SUCCESS = '@newFeatureFlags/FETCH_SUCCESS',
  FETCH_FAILURE = '@newFeatureFlags/FETCH_FAILURE',
}

// Data types

// State type
export interface FeatureFlagsState extends Map<string, any> {
  readonly data?: Map<string, string | undefined>;
  readonly flagInfo?: ImmutableMap<Record<string, FeatureFlags | undefined>>;
  readonly loading: boolean;
  readonly error?: boolean | number;
  readonly variantAttachment?: Map<string, string | undefined>;
}

// Fetch actions
export const fetchFeatureFlagRequest = (data: FlagEvaluationRequest) =>
  action(FeatureFlagsTypes.FETCH_REQUEST, data);

export const fetchFeatureFlagSuccess = (data: any) =>
  action(FeatureFlagsTypes.FETCH_SUCCESS, data);

export const fetchFeatureFlagFailure = () =>
  action(FeatureFlagsTypes.FETCH_FAILURE);

// Sagas
export function* fetchFeatureFlag(
  action: AnyAction,
): Generator<StrictEffect, void, any> {
  try {
    const response = yield call(fetchFeatureFlagService, action.payload);
    yield put(fetchFeatureFlagSuccess(response.data));
  } catch (err) {
    yield put(fetchFeatureFlagFailure());
  }
}

// Selectors
const featureFlagsDataSelector = (state: ApplicationState) =>
  state.get('featureFlags');

export const getFeatureFlags = createSelector(
  featureFlagsDataSelector,
  (featureFlags) => featureFlags.get('data'),
);

export const getVariantAttachment = createSelector(
  featureFlagsDataSelector,
  (featureFlags) => featureFlags.get('variantAttachment'),
);

export const getFeatureFlagsInfo = createSelector(
  featureFlagsDataSelector,
  (featureFlags) => featureFlags.get('flagInfo'),
);

export const isLoadingFeatureFlags = createSelector(
  featureFlagsDataSelector,
  (featureFlags) => featureFlags.get('loading'),
);

// Initial state
export const INITIAL_STATE: FeatureFlagsState = fromJS({
  data: fromJS({}),
  flagInfo: fromJS({}),
  error: false,
  loading: false,
});

// Reducer
export const reducer: Reducer<FeatureFlagsState | undefined> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case FeatureFlagsTypes.FETCH_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('loading', true).set('error', false),
      );

    case FeatureFlagsTypes.FETCH_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .setIn(['flagInfo', action.payload.flagKey], action.payload)
          .setIn(['data', action.payload.flagKey], action.payload.variantKey)
          .setIn(
            ['variantAttachment', action.payload.flagKey],
            action.payload.variantAttachment,
          ),
      );

    case FeatureFlagsTypes.FETCH_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', true),
      );

    default:
      return state;
  }
};

export default reducer;
