import api from './api';

export const updateProfileService = (userData: any) =>
  api(`/api/v1/user_profile/${userData.id}/`, {
    method: 'patch',
    data: {
      ...userData,
    },
  });

export const fetchProfileService = () => api(`/api/v1/user_profile/`);
