import {AxiosResponse} from 'axios';
import {FeatureFlags} from 'utils/types/featureFlags';
import api from './api';

export interface FlagEvaluationRequest {
  flagKey: string;
  entityId?: string;
  entityContext?: Record<string, unknown>;
}

export const fetchFeatureFlagService = (
  data: FlagEvaluationRequest,
): Promise<AxiosResponse<FeatureFlags>> =>
  api('https://flags.letrus.com.br/api/v1/evaluation/', {method: 'post', data});
