import {takeLatest} from 'redux-saga/effects';
import {
  fetchQuestionnaireAnswersByStep,
  fetchQuestionnaireAnswersByStudent,
  fetchQuestionnaireById,
  postQuestionAnswers,
  QuestionnairesTypes,
} from '../reducers/questionnaires';

const questionnaireSagas = [
  takeLatest(
    QuestionnairesTypes.FETCH_QUESTIONNAIRE_BY_ID_REQUEST,
    fetchQuestionnaireById,
  ),
  takeLatest(
    QuestionnairesTypes.POST_QUESTION_ANSWER_REQUEST,
    postQuestionAnswers,
  ),
  takeLatest(
    QuestionnairesTypes.FETCH_QUESTIONNAIRE_ANSWERS_BY_STEP_REQUEST,
    fetchQuestionnaireAnswersByStep,
  ),
  takeLatest(
    QuestionnairesTypes.FETCH_QUESTIONNAIRE_ANSWERS_BY_STUDENT_REQUEST,
    fetchQuestionnaireAnswersByStudent,
  ),
];

export default questionnaireSagas;
