import {takeLatest} from 'redux-saga/effects';
import {
  createUserFeelings,
  fetchUserFeelings,
  UserFeelingsTypes,
} from 'store/reducers/userFeelings';

const userFeelingsSagas = [
  takeLatest(UserFeelingsTypes.FETCH_USER_FEELINGS_REQUEST, fetchUserFeelings),
  takeLatest(
    UserFeelingsTypes.CREATE_USER_FEELINGS_REQUEST,
    createUserFeelings,
  ),
];

export default userFeelingsSagas;
