import {takeLatest} from 'redux-saga/effects';
import {
  fetchServerStatus,
  LetrusGlobalConfigurationTypes,
} from '../reducers/globalConfiguration';

const globalConfigurationSagas = [
  takeLatest(
    LetrusGlobalConfigurationTypes.FETCH_SERVER_STATUS_REQUEST,
    fetchServerStatus,
  ),
];

export default globalConfigurationSagas;
