import {LetrusApi} from '@letrustech/letrus-api-interfaces';
import {fromJS, Map} from 'immutable';
import {AnyAction, Reducer} from 'redux';
import {call, put, StrictEffect} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {ApplicationState} from 'store/rootReducer';
import {fetchPedagogicalContentByIdService} from 'store/services/pedagogicalContentServices';
import {action} from 'typesafe-actions';

// Action types
export enum PedagogicalContentsTypes {
  FETCH_PEDAGOGICAL_CONTENT_BY_ID_REQUEST = '@pedagogicalContents/FETCH_PEDAGOGICAL_CONTENT_BY_ID_REQUEST',
  FETCH_PEDAGOGICAL_CONTENT_BY_ID_SUCCESS = '@pedagogicalContents/FETCH_PEDAGOGICAL_CONTENT_BY_ID_SUCCESS',
  FETCH_PEDAGOGICAL_CONTENT_BY_ID_FAILURE = '@pedagogicalContents/FETCH_PEDAGOGICAL_CONTENT_BY_ID_FAILURE',
}

// Data types

// State type
export interface PedagogicalContentsState extends Map<string, any> {
  readonly isLoadingPedagogicalContent: boolean;
  readonly error: boolean;
  readonly pedagogicalContentById: ImmutableMap<LetrusApi.PedagogicalContentNested>;
}

// Fetch pedagogicalContent Actions
export const fetchPedagogicalContentByIdRequest = (
  pedagogicalContentById: string | number,
) =>
  action(PedagogicalContentsTypes.FETCH_PEDAGOGICAL_CONTENT_BY_ID_REQUEST, {
    pedagogicalContentById,
  });

export const fetchPedagogicalContentByIdSuccess = (
  data: LetrusApi.PedagogicalContentNested,
) =>
  action(
    PedagogicalContentsTypes.FETCH_PEDAGOGICAL_CONTENT_BY_ID_SUCCESS,
    data,
  );

export const fetchPedagogicalContentByIdFailure = () =>
  action(PedagogicalContentsTypes.FETCH_PEDAGOGICAL_CONTENT_BY_ID_FAILURE);

// Sagas
export function* fetchPedagogicalContentById(
  action: AnyAction,
): Generator<StrictEffect, void, {data: LetrusApi.PedagogicalContentNested}> {
  try {
    const response = yield call(
      fetchPedagogicalContentByIdService,
      action.payload.pedagogicalContentById,
    );

    yield put(fetchPedagogicalContentByIdSuccess(response.data));
  } catch (error) {
    yield put(fetchPedagogicalContentByIdFailure());
  }
}

// Initial State
export const INITIAL_STATE: PedagogicalContentsState = fromJS({
  pedagogicalContentById: fromJS({}),
  isLoadingPedagogicalContent: false,
  error: false,
});

// Selectors
const pedagogicalContentSelector = (state: ApplicationState) =>
  state.get('pedagogicalContents');

export const isLoadingPedagogicalContent = createSelector(
  pedagogicalContentSelector,
  (pedagogicalContent) => pedagogicalContent.get('isLoadingPedagogicalContent'),
);

export const getPedagogicalContentById = createSelector(
  pedagogicalContentSelector,
  (pedagogicalContent) => pedagogicalContent.get('pedagogicalContentById'),
);

export const getPedagogicalContentError = createSelector(
  pedagogicalContentSelector,
  (pedagogicalContent) => pedagogicalContent.get('error'),
);

// Reducer
const reducer: Reducer<PedagogicalContentsState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case PedagogicalContentsTypes.FETCH_PEDAGOGICAL_CONTENT_BY_ID_REQUEST: {
      return state.withMutations((prevState) =>
        prevState.set('isLoadingPedagogicalContent', true).set('error', false),
      );
    }

    case PedagogicalContentsTypes.FETCH_PEDAGOGICAL_CONTENT_BY_ID_SUCCESS: {
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingPedagogicalContent', false)
          .set('error', false)
          .set('pedagogicalContentById', fromJS(action.payload)),
      );
    }

    case PedagogicalContentsTypes.FETCH_PEDAGOGICAL_CONTENT_BY_ID_FAILURE: {
      return state.withMutations((prevState) =>
        prevState.set('isLoadingPedagogicalContent', false).set('error', true),
      );
    }

    default:
      return state;
  }
};

export default reducer;
