import {takeLatest} from 'redux-saga/effects';
import {
  createLearningPathUserStepTimeSpent,
  fetchLearningPathUserStepTimeSpent,
  LearningPathUserStepTimeSpentTypes,
  updateLearningPathUserStepTimeSpent,
} from 'store/reducers/learningPathUserStepTimeSpent';

const learningPathUserStepTimeSpentSagas = [
  takeLatest(
    LearningPathUserStepTimeSpentTypes.FETCH_LEARNING_PATH_USER_STEP_TIME_SPENT_REQUEST,
    fetchLearningPathUserStepTimeSpent,
  ),
  takeLatest(
    LearningPathUserStepTimeSpentTypes.CREATE_LEARNING_PATH_USER_STEP_TIME_SPENT_REQUEST,
    createLearningPathUserStepTimeSpent,
  ),
  takeLatest(
    LearningPathUserStepTimeSpentTypes.UPDATE_LEARNING_PATH_USER_STEP_TIME_SPENT_REQUEST,
    updateLearningPathUserStepTimeSpent,
  ),
];

export default learningPathUserStepTimeSpentSagas;
