import {getParagraphs} from './getParagraphs';

/**
 * Function that identifies when same word
 * @param {string} text - Input text.
 * @returns {list} List of mistakes.
 */
export function getRepeatedSequences(text) {
  const cmd = '\\b([a-zA-ZÇÁÉÍÓÚÂÊÎÔÛÀÈÌÒÙÃÕÜçáéíóúâêîôûàèìòùãõü]+) \\1\\b';
  const regex = new RegExp(cmd, 'igm');
  const paragraphs = getParagraphs(text);
  const mistakes = [];

  for (let i = 0; i < paragraphs.length; i++) {
    let match;
    const paragraph = paragraphs[i].text;
    do {
      match = regex.exec(paragraph);
      if (match) {
        const start = match.index;
        const end = regex.lastIndex;
        mistakes.push({
          boundaries: [start, end],
          paragraph: i,
          suggestions: [paragraph.substring(start, end).split(' ')[0]],
          text: paragraph.substring(start, end),
          message: 'Palavra repetida. Atenção ao digitar a redação.',
        });
      }
    } while (match);
  }

  return mistakes;
}

/**
 * Verify if a string is a roman numerical (avoid confusion
 * with acronyms).
 * @param {string} roman - roman number (e.g. IX).
 * @returns {boolean} true or false.
 */
function isRomanNumeral(roman) {
  const romans = [
    'M',
    'CM',
    'D',
    'CD',
    'C',
    'XC',
    'L',
    'XL',
    'X',
    'IX',
    'V',
    'IV',
    'I',
  ];

  let k = 0;
  let copyRoman = roman;
  for (let i = 0; i <= romans.length; i++) {
    while (copyRoman.indexOf(romans[i]) === 0) {
      copyRoman = copyRoman.replace(romans[i], '');
      k += romans[i].length;
    }
  }

  if (roman.length === k) {
    return true;
  }

  return false;
}

/**
 * Function that identifies if a acronyms has an explanation within "()".
 * @param {string} text - input text
 * @returns {list} list of mistakes.
 */
export function getAcronymsWithoutExplanation(text) {
  const regex = /\b[A-Z]{3,8}\b/gm;
  const matches = {};
  let match;

  // Get the first occurrence of all acronyms.
  do {
    match = regex.exec(text);
    if (match) {
      const start = match.index;
      const end = regex.lastIndex;
      const acronym = text.substring(start, end);
      if (!(acronym in matches)) {
        matches[acronym] = [start, end];
      }
    }
  } while (match);

  if (!matches) {
    return [];
  }

  // Get acronyms without explanation (1st parenthesis "(").
  const mistakes = [];
  const paragraphs = getParagraphs(text);
  // eslint-disable-next-line no-restricted-syntax
  for (const key in matches) {
    // eslint-disable-next-line no-prototype-builtins
    if (matches.hasOwnProperty(key)) {
      const start = matches[key][0];
      const end = matches[key][1];
      // eslint-disable-next-line prefer-template
      if (text.indexOf(key + ' (', start) === -1 && !isRomanNumeral(key)) {
        for (let i = 0; i < paragraphs.length; i++) {
          const boundaries = paragraphs[i].boundariesTotal;
          if (
            start >= boundaries[0] &&
            end <= boundaries[1] &&
            paragraphs[i].text.indexOf(key) > 0
          ) {
            const s = paragraphs[i].text.indexOf(key);
            const e = s + key.length;
            mistakes.push({
              boundaries: [s, e],
              paragraph: i,
              text: key,
              suggestions: [],
              examples: [
                'Segundo o IBGE (Instituto Brasileiro de Geografia e ' +
                  'Estátistica), 47% de jovens sofrem agressões físicas, ' +
                  'ou psicológicas e todos esses adolescentes já ouviram ' +
                  'uma piada sobre eles.',
              ],
              message:
                'É importante que uma sigla apresentada pela primeira vez ' +
                'no texto tenha seu significado explicitado. Coloque, ' +
                'entre parênteses, a qual empresa ou organização ela está ' +
                'se remetendo.',
            });
            break;
          }
        }
      }
    }
  }

  return mistakes;
}

/**
 * Function that identifies all paragraphs that contains only 1 sentence,
 * in other words, less than 2 periods (phrasal paragraph).
 * @param {list} sentences - list of sentences (objects)
 * @returns {list} list of mistakes.
 */
function getPhrasalParagraphs(text) {
  return getParagraphs(text)
    .map((paragraph) => {
      if (
        paragraph.text.match(/(\.|\?|!)(\s|$)/gm) &&
        paragraph.text.match(/(\.|\?|!)(\s|$)/gm).length < 2
      ) {
        return {
          boundaries: paragraph.boundaries,
          paragraph: paragraph.paragraph,
          text: paragraph.text,
          suggestions: [],
          message:
            'Veja como esse parágrafo está curto. As ideias contidas nele ' +
            'estariam muito mais desenvolvidas se você tivesse apresentando ' +
            'maiores detalhes sobre o assunto. Para que seus textos fiquem ' +
            'cada vez mais elaborados, escreva mais que 2 períodos por ' +
            'parágrafo.',
        };
      }

      return;
    })
    .filter((value) => value);
}

/**
 * A dissertation text can only have up to 3 questions and only one question
 * at the final paragraph (conclusion).
 * This function returns the number of mistakes using questions marks ("?").
 * @param {list} paragraphs - list of paragraphs (objects).
 * @returns {list} list of mistakes.
 */
function getQuestionIssues(text) {
  const regex = /\?/gm;
  const paragraphs = getParagraphs(text);

  // Verify if the last paragraph has a question
  const lastParagraph = paragraphs.slice(-1).pop();
  if (
    lastParagraph &&
    lastParagraph.text.match(regex) &&
    lastParagraph.text.match(regex).length >= 1
  ) {
    const match = regex.exec(lastParagraph.text);
    return [
      {
        boundaries: [match.index, match.index + 1],
        paragraph: lastParagraph.paragraph,
        text: lastParagraph.text,
        suggestions: [],
        message:
          'O último parágrafo de um texto dissertativo deve conter um breve ' +
          'resumo das principais ideias abordadas e o fechamento da tese, ' +
          'ele é, portanto, uma conclusão. Assim, no final da sua redação, ' +
          'evite apresentar novas ideias, bem como realizar perguntas, pois ' +
          'não haverá espaço para que elas sejam respondidas com propriedade.',
      },
    ];
  }

  // Get total number of question marks on text
  const nQuestionsMarks = paragraphs
    .map((paragraph) => {
      if (paragraph.text.match(regex)) {
        return paragraph.text.match(regex).length;
      }
      return 0;
    })
    .reduce((a, b) => a + b, 0);

  // If number of question marks is lower than 4, do nothing
  if (nQuestionsMarks <= 3) {
    return [];
  }

  // Otherwise, returns question marks issues (mistakes)
  const mistakes = [];
  for (let i = 0; i < paragraphs.length; i++) {
    let match;
    do {
      match = regex.exec(paragraphs[i].text);
      if (match) {
        mistakes.push({
          boundaries: [match.index, match.index + 1],
          paragraph: i,
          text: '?',
          suggestions: [],
          message:
            'As perguntas, muitas vezes, são ótimas estratégias ' +
            'argumentativas, pois ajudam a expor um questionamento ao' +
            'leitor, um problema sobre o assunto e, até mesmo, sua teste. ' +
            'Mas cuidado: quando em grande quantidade, as interrogações ' +
            'podem demonstrar um baixo repertório em relação à argumentação ' +
            'e, inclusive, aproximar seu texto de uma linguagem mais informal.',
        });
      }
    } while (match);
  }

  return mistakes;
}

/**
 * Identify special cases (mistakes).
 * @param {string} text - input text.
 * @param {list} paragraphs - list of paragraphs (objects).
 * @param {list} sentences - list of sentences (objects).
 * @returns {list} list of mistakes.
 */
export default function getSpecialCases(text) {
  const a = getRepeatedSequences(text);
  const b = getAcronymsWithoutExplanation(text);
  const c = getPhrasalParagraphs(text);
  const d = getQuestionIssues(text);

  return a.concat(b, c, d);
}
