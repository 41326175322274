import {AnyAction, Reducer} from 'redux';
import {call, put, StrictEffect} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {ApplicationState} from 'store/rootReducer';
import {
  createUserFeelingsService,
  DisplayEmotion,
  Emotion,
  fetchUserFeelingsService,
  UserFeelingsData,
} from 'store/services/userFeelings';
import {action} from 'typesafe-actions';
import {StoreStatus} from 'utils/types/store';

// Action types
export enum UserFeelingsTypes {
  FETCH_USER_FEELINGS_REQUEST = '@userFeelings/FETCH_USER_FEELINGS_REQUEST',
  FETCH_USER_FEELINGS_SUCCESS = '@userFeelings/FETCH_USER_FEELINGS_SUCCESS',
  FETCH_USER_FEELINGS_FAILURE = '@userFeelings/FETCH_USER_FEELINGS_FAILURE',

  CREATE_USER_FEELINGS_REQUEST = '@userFeelings/CREATE_USER_FEELINGS_REQUEST',
  CREATE_USER_FEELINGS_SUCCESS = '@userFeelings/CREATE_USER_FEELINGS_SUCCESS',
  CREATE_USER_FEELINGS_FAILURE = '@userFeelings/CREATE_USER_FEELINGS_FAILURE',
}

export interface UserFeelingsStateData
  extends Omit<UserFeelingsData, 'choice'> {
  choice: {
    value: Emotion;
    display: DisplayEmotion;
  };
}

// State type
export interface UserFeelingsState {
  readonly userFeelingsData: UserFeelingsStateData | undefined;
  readonly userFeelingsRequestStatus: StoreStatus;
}

// FETCH user feelings Actions
export const fetchUserFeelingsRequest = (compositionId: string | number) =>
  action(UserFeelingsTypes.FETCH_USER_FEELINGS_REQUEST, {compositionId});

export const fetchUserFeelingsSuccess = (userFeelingsData: UserFeelingsData) =>
  action(UserFeelingsTypes.FETCH_USER_FEELINGS_SUCCESS, {userFeelingsData});

export const fetchUserFeelingsFailure = () =>
  action(UserFeelingsTypes.FETCH_USER_FEELINGS_FAILURE);

// CREATE user feelings Actions
export const createUserFeelingsRequest = (
  userFeelingsData: Partial<UserFeelingsData>,
) => action(UserFeelingsTypes.CREATE_USER_FEELINGS_REQUEST, {userFeelingsData});

export const createUserFeelingsSuccess = (userFeelingsData: UserFeelingsData) =>
  action(UserFeelingsTypes.CREATE_USER_FEELINGS_SUCCESS, {userFeelingsData});

export const createUserFeelingsFailure = () =>
  action(UserFeelingsTypes.CREATE_USER_FEELINGS_FAILURE);

// Sagas
export function* fetchUserFeelings(
  action: AnyAction,
): Generator<StrictEffect, void, {data: UserFeelingsData}> {
  try {
    const response = yield call(
      fetchUserFeelingsService,
      action.payload.compositionId,
    );
    yield put(fetchUserFeelingsSuccess(response.data));
  } catch (error) {
    yield put(fetchUserFeelingsFailure());
  }
}

export function* createUserFeelings(
  action: AnyAction,
): Generator<StrictEffect, void, {userFeelingsData: UserFeelingsData}> {
  try {
    const response = yield call(
      createUserFeelingsService,
      action.payload.userFeelingsData,
    );

    yield put(createUserFeelingsSuccess(response.userFeelingsData));
  } catch (error) {
    yield put(createUserFeelingsFailure());
  }
}

// Initial state
export const INITIAL_STATE: UserFeelingsState = {
  userFeelingsData: undefined,
  userFeelingsRequestStatus: {
    fulfilled: false,
    loading: false,
    error: false,
    posting: false,
  },
};

// Selectors
export const userFeelingsSelector = (state: ApplicationState) =>
  state.get('userFeelings') as UserFeelingsState;

export const getUserFeelingsData = createSelector(
  userFeelingsSelector,
  (state) => state.userFeelingsData,
);

export const getUserFeelingsRequestStatus = createSelector(
  userFeelingsSelector,
  (state) => state.userFeelingsRequestStatus,
);

// STATE
const reducer: Reducer<UserFeelingsState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserFeelingsTypes.FETCH_USER_FEELINGS_REQUEST: {
      return {
        ...state,
        userFeelingsRequestStatus: {
          ...state.userFeelingsRequestStatus,
          loading: true,
        },
      };
    }
    case UserFeelingsTypes.FETCH_USER_FEELINGS_SUCCESS: {
      return {
        ...state,
        userFeelingsData: action.payload.userFeelingsData,
        userFeelingsRequestStatus: {
          ...state.userFeelingsRequestStatus,
          fulfilled: true,
          loading: false,
        },
      };
    }
    case UserFeelingsTypes.FETCH_USER_FEELINGS_FAILURE: {
      return {
        ...state,
        userFeelingsRequestStatus: {
          ...state.userFeelingsRequestStatus,
          error: true,
          loading: false,
        },
      };
    }
    case UserFeelingsTypes.CREATE_USER_FEELINGS_REQUEST: {
      return {
        ...state,
        userFeelingsRequestStatus: {
          ...state.userFeelingsRequestStatus,
          posting: true,
        },
      };
    }
    case UserFeelingsTypes.CREATE_USER_FEELINGS_SUCCESS: {
      return {
        ...state,
        userFeelingsData: action.payload.userFeelingsData,
        userFeelingsRequestStatus: {
          ...state.userFeelingsRequestStatus,
          fulfilled: true,
          posting: false,
        },
      };
    }
    case UserFeelingsTypes.CREATE_USER_FEELINGS_FAILURE: {
      return {
        ...state,
        userFeelingsRequestStatus: {
          ...state.userFeelingsRequestStatus,
          error: true,
          posting: false,
        },
      };
    }
    default:
      return state;
  }
};

export default reducer;
