import {AnyAction, Reducer} from 'redux';
import {call, put} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {ApplicationState} from 'store/rootReducer';
import {
  fetchProfileService,
  updateProfileService,
} from 'store/services/profileServices';
import {action} from 'typesafe-actions';
import {ProfileData, ProfileDetail} from 'utils/types/profile';
import {StoreStatus} from 'utils/types/store';
import {updateUserProfile} from '../authentication';

// Action types
export enum ProfileTypes {
  FETCH_PROFILE_REQUEST = '@profile/FETCH_PROFILE_REQUEST',
  FETCH_PROFILE_SUCCESS = '@profile/FETCH_PROFILE_SUCCESS',
  FETCH_PROFILE_FAILURE = '@profile/FETCH_PROFILE_FAILURE',

  UPDATE_PROFILE_REQUEST = '@profile/UPDATE_PROFILE_REQUEST',
  UPDATE_PROFILE_SUCCESS = '@profile/UPDATE_PROFILE_SUCCESS',
  UPDATE_PROFILE_FAILURE = '@profile/UPDATE_PROFILE_FAILURE',

  FETCH_PROFILE_BY_ID_REQUEST = '@profile/FETCH_PROFILE_BY_ID_REQUEST',
  FETCH_PROFILE_BY_ID_SUCCESS = '@profile/FETCH_PROFILE_BY_ID_SUCCESS',
  FETCH_PROFILE_BY_ID_FAILURE = '@profile/FETCH_PROFILE_BY_ID_FAILURE',
}

// State type
export interface ProfileState {
  readonly profile?: ProfileDetail;
  readonly profileRequestStatus: StoreStatus;
  readonly updateProfileRequestStatus: StoreStatus;
  readonly profileByIdRequestStatus: StoreStatus;
}

// Fetch actions

export const fetchProfileRequest = () =>
  action(ProfileTypes.FETCH_PROFILE_REQUEST);

export const fetchProfileSuccess = (data: ProfileData) =>
  action(ProfileTypes.FETCH_PROFILE_SUCCESS, {data});

export const fetchProfileFailure = () =>
  action(ProfileTypes.FETCH_PROFILE_FAILURE);

export const updateProfileRequest = (profileData: Partial<ProfileDetail>) =>
  action(ProfileTypes.UPDATE_PROFILE_REQUEST, {profileData});

export const updateProfileSuccess = (data: ProfileData) =>
  action(ProfileTypes.UPDATE_PROFILE_SUCCESS, {data});

export const updateProfileFailure = () =>
  action(ProfileTypes.UPDATE_PROFILE_FAILURE);

// Sagas

export function* updateProfile(action: AnyAction): Generator<any, void, any> {
  try {
    const response = yield call(
      updateProfileService,
      action.payload.profileData,
    );
    yield put(updateUserProfile(response.data)); // update authentication store
    yield put(updateProfileSuccess(response.data));
  } catch (err) {
    yield put(updateProfileFailure());
  }
}

export function* fetchProfile(): Generator<any, void, any> {
  try {
    const response = yield call(fetchProfileService);
    yield put(fetchProfileSuccess(response.data));
  } catch (err) {
    yield put(fetchProfileFailure());
  }
}

// Selectors
const profileSelector = (state: ApplicationState) =>
  state.get('profile') as ProfileState;

export const getDataProfile = createSelector(
  profileSelector,
  (profile) => profile.profile,
);

export const getProfileRequestStatus = createSelector(
  profileSelector,
  (profile) => profile.profileRequestStatus,
);

export const getUpdateProfileRequestStatus = createSelector(
  profileSelector,
  (profile) => profile.updateProfileRequestStatus,
);

export const getProfileByIdRequestStatus = createSelector(
  profileSelector,
  (profile) => profile.profileByIdRequestStatus,
);

// Initial state

export const INITIAL_STATE: ProfileState = {
  profile: undefined,
  profileRequestStatus: {
    error: false,
    fulfilled: false,
    loading: false,
    posting: false,
  },
  updateProfileRequestStatus: {
    error: false,
    fulfilled: false,
    loading: false,
    posting: false,
  },
  profileByIdRequestStatus: {
    error: false,
    fulfilled: false,
    loading: false,
    posting: false,
  },
};

// Reducer
const reducer: Reducer<ProfileState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ProfileTypes.FETCH_PROFILE_REQUEST:
      return {
        ...state,
        profileRequestStatus: {
          loading: true,
          fulfilled: false,
          error: false,
          posting: false,
        },
      };

    case ProfileTypes.FETCH_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.payload.data.results[0],
        profileRequestStatus: {
          loading: false,
          fulfilled: true,
          error: false,
          posting: false,
        },
      };

    case ProfileTypes.FETCH_PROFILE_FAILURE:
      return {
        ...state,
        profileRequestStatus: {
          loading: false,
          fulfilled: false,
          error: true,
          posting: false,
        },
      };

    case ProfileTypes.UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        updateProfileRequestStatus: {
          loading: true,
          fulfilled: false,
          error: false,
          posting: false,
        },
      };

    case ProfileTypes.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.payload.data,
        updateProfileRequestStatus: {
          loading: false,
          fulfilled: true,
          error: false,
          posting: false,
        },
      };

    case ProfileTypes.UPDATE_PROFILE_FAILURE:
      return {
        ...state,
        updateProfileRequestStatus: {
          loading: false,
          fulfilled: false,
          error: true,
          posting: false,
        },
      };
    case ProfileTypes.FETCH_PROFILE_BY_ID_REQUEST:
      return {
        ...state,
        profileByIdRequestStatus: {
          loading: true,
          fulfilled: false,
          error: false,
          posting: false,
        },
      };

    case ProfileTypes.FETCH_PROFILE_BY_ID_SUCCESS:
      return {
        ...state,
        profileInfo: action.payload.success,
        profileByIdRequestStatus: {
          loading: false,
          fulfilled: true,
          error: false,
          posting: false,
        },
      };

    case ProfileTypes.FETCH_PROFILE_BY_ID_FAILURE:
      return {
        ...state,
        profileByIdRequestStatus: {
          loading: false,
          fulfilled: false,
          error: true,
          posting: false,
        },
      };

    default:
      return state;
  }
};

export default reducer;
