import {Utils} from '@letrustech/letrus-api-interfaces';
import {AxiosResponse} from 'axios';
import {AnyAction, Reducer} from 'redux';
import {call, put} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import store from 'store';
import {ApplicationState} from 'store/rootReducer';
import {fetchActivitiesService} from 'store/services/userActivitiesService';
import {action} from 'typesafe-actions';
import {StoreStatus} from 'utils/types/store';
import {ActivitiesByStatus} from 'utils/types/userActivities';
import {clearComposition} from '../compositions';
import {clearLearningPath} from '../learningPath';
import {clearQuestionnaireAnswers} from '../questionnaires';

export enum UserActivitiesActionTypes {
  FETCH_ACTIVITY_LIST_REQUEST = '@activities/FETCH_ACTIVITY_LIST_REQUEST',
  FETCH_ACTIVITY_LIST_SUCCESS = '@activities/FETCH_ACTIVITY_LIST_SUCCESS',
  FETCH_ACTIVITY_LIST_FAILURE = '@activities/FETCH_ACTIVITY_LIST_FAILURE',

  UPDATE_ACTIVITY_LIST_REQUEST = '@activities/UPDATE_ACTIVITY_LIST_REQUEST',
  UPDATE_ACTIVITY_LIST_SUCCESS = '@activities/UPDATE_ACTIVITY_LIST_SUCCESS',
  UPDATE_ACTIVITY_LIST_FAILURE = '@activities/UPDATE_ACTIVITY_LIST_FAILURE',
}

export interface UserActivitiesState {
  activities?: ActivitiesByStatus;
  activitiesRequestStatus: StoreStatus;
  updateActivitiesRequestStatus: StoreStatus;
}

// Actions
export const fetchActivitiesRequest = (params?: Utils.GetParams): AnyAction =>
  action(UserActivitiesActionTypes.FETCH_ACTIVITY_LIST_REQUEST, {params});

export const fetchActivitiesSuccess = (
  activities: ActivitiesByStatus,
): AnyAction =>
  action(UserActivitiesActionTypes.FETCH_ACTIVITY_LIST_SUCCESS, activities);

export const fetchActivitiesFailure = (): AnyAction =>
  action(UserActivitiesActionTypes.FETCH_ACTIVITY_LIST_FAILURE);

export const updateActivitiesRequest = (params?: Utils.GetParams): AnyAction =>
  action(UserActivitiesActionTypes.UPDATE_ACTIVITY_LIST_REQUEST, {params});

export const updateActivitiesSuccess = (
  activities: ActivitiesByStatus,
): AnyAction =>
  action(UserActivitiesActionTypes.UPDATE_ACTIVITY_LIST_SUCCESS, activities);

export const updateActivitiesFailure = (): AnyAction =>
  action(UserActivitiesActionTypes.UPDATE_ACTIVITY_LIST_FAILURE);

export const clearStoreActivities = () => {
  store.dispatch(clearComposition());
  store.dispatch(clearLearningPath());
  store.dispatch(clearQuestionnaireAnswers());
};

// Sagas
export function* fetchActivities(action: AnyAction) {
  try {
    const response: AxiosResponse<ActivitiesByStatus> = yield call(
      fetchActivitiesService,
      action.payload.params,
    );
    yield put(fetchActivitiesSuccess(response.data));
  } catch (error) {
    yield put(fetchActivitiesFailure());
  }
}

export function* updateActivities(action: AnyAction) {
  try {
    const response: AxiosResponse<ActivitiesByStatus> = yield call(
      fetchActivitiesService,
      action.payload.params,
    );
    yield put(updateActivitiesSuccess(response.data));
  } catch (error) {
    yield put(updateActivitiesFailure());
  }
}

// Selectors
const userActivitiesSelector = (state: ApplicationState) =>
  state.get('userActivities') as UserActivitiesState;

export const getActivities = createSelector(
  userActivitiesSelector,
  (userActivitiesState) => userActivitiesState.activities,
);

export const getActivitiesRequestStatus = createSelector(
  userActivitiesSelector,
  (userActivitiesState) => userActivitiesState.activitiesRequestStatus,
);

// Initial state
export const INITIAL_STATE: UserActivitiesState = {
  activities: undefined,
  activitiesRequestStatus: {
    fulfilled: false,
    loading: false,
    error: false,
    posting: false,
  },
  updateActivitiesRequestStatus: {
    fulfilled: false,
    loading: false,
    error: false,
    posting: false,
  },
};

// Reducer
const reducer: Reducer<UserActivitiesState, AnyAction> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case UserActivitiesActionTypes.FETCH_ACTIVITY_LIST_REQUEST:
      return {
        ...state,
        activitiesRequestStatus: {
          ...state.activitiesRequestStatus,
          loading: true,
          error: false,
          fulfilled: false,
        },
      };

    case UserActivitiesActionTypes.FETCH_ACTIVITY_LIST_SUCCESS:
      return {
        ...state,
        activities: action.payload,
        activitiesRequestStatus: {
          ...state.activitiesRequestStatus,
          loading: false,
          fulfilled: true,
          error: false,
        },
      };

    case UserActivitiesActionTypes.FETCH_ACTIVITY_LIST_FAILURE:
      return {
        ...state,
        activitiesRequestStatus: {
          ...state.activitiesRequestStatus,
          loading: false,
          error: true,
          fulfilled: false,
        },
      };

    case UserActivitiesActionTypes.UPDATE_ACTIVITY_LIST_REQUEST:
      return {
        ...state,
        updateActivitiesRequestStatus: {
          ...state.activitiesRequestStatus,
          loading: true,
          error: false,
          fulfilled: false,
        },
      };

    case UserActivitiesActionTypes.UPDATE_ACTIVITY_LIST_SUCCESS:
      return {
        ...state,
        activities: action.payload,
        updateActivitiesRequestStatus: {
          ...state.activitiesRequestStatus,
          loading: false,
          fulfilled: true,
          error: false,
        },
      };

    case UserActivitiesActionTypes.UPDATE_ACTIVITY_LIST_FAILURE:
      return {
        ...state,
        updateActivitiesRequestStatus: {
          ...state.activitiesRequestStatus,
          loading: false,
          error: true,
          fulfilled: false,
        },
      };

    default:
      return state;
  }
};

export default reducer;
