import {fromJS, Map} from 'immutable';
import {AnyAction, Reducer} from 'redux';
import {call, put} from 'redux-saga/effects';
import {addLikeInReviewAnnotationService} from 'store/services/reviewAnnotationServices';
import {action} from 'typesafe-actions';

// Action types
export enum ReviewAnnotationTypes {
  ADD_LIKE_REQUEST = '@reviewAnnotations/ADD_LIKE_REQUEST',
  ADD_LIKE_SUCCESS = '@reviewAnnotations/ADD_LIKE_SUCCESS',
  ADD_LIKE_FAILURE = '@reviewAnnotations/ADD_LIKE_FAILURE',
}

// Data types

// State type
export interface ReviewAnnotationsState extends Map<string, any> {
  readonly data?: Map<string, string | undefined>;
  readonly loading: boolean;
  readonly error?: boolean | number;
}

// Fetch actions
export const addLikeInReviewAnnotationRequest = (
  reviewAnnotationId: string | number,
  like: boolean,
) => action(ReviewAnnotationTypes.ADD_LIKE_REQUEST, {reviewAnnotationId, like});

export const addLikeInReviewAnnotationSuccess = () =>
  action(ReviewAnnotationTypes.ADD_LIKE_SUCCESS);

export const addLikeInReviewAnnotationFailure = () =>
  action(ReviewAnnotationTypes.ADD_LIKE_FAILURE);

// Sagas
export function* addLikeInReviewAnnotation(action: AnyAction) {
  try {
    yield call(
      addLikeInReviewAnnotationService,
      action.payload.reviewAnnotationId,
      action.payload.like,
    );
    yield put(addLikeInReviewAnnotationSuccess());
  } catch (err) {
    yield put(addLikeInReviewAnnotationFailure());
  }
}

// Selectors

// Initial state
export const INITIAL_STATE: ReviewAnnotationsState = fromJS({
  data: fromJS({}),
  error: false,
  loading: false,
});

// Reducer
export const reducer: Reducer<ReviewAnnotationsState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case ReviewAnnotationTypes.ADD_LIKE_REQUEST:
      return state.withMutations((prevState) => prevState.set('loading', true));

    case ReviewAnnotationTypes.ADD_LIKE_SUCCESS:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', false),
      );

    case ReviewAnnotationTypes.ADD_LIKE_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', true),
      );

    default:
      return state;
  }
};

export default reducer;
