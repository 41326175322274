import {ContentState, EditorState} from 'draft-js';
import {CompositionDetailExtended} from 'utils/types/compositions';

export const reviveEditorState = (payload: CompositionDetailExtended): any => {
  if (!payload.composition_raw) {
    return EditorState.createEmpty();
  }

  return EditorState.createWithContent(
    ContentState.createFromText(
      payload.composition_raw
        .split('\n')
        .filter((line) => line)
        .join('\n') || '',
    ),
  );
};
