import {Map} from 'immutable';
import {combineReducers} from 'redux-immutable';
import apiSyncReducer, {ApiSyncState} from './reducers/apiSync';
import authenticationReducer, {
  AuthenticationState,
} from './reducers/authentication';
import compositionsReducer, {CompositionsState} from './reducers/compositions';
import featureFlagsReducer, {FeatureFlagsState} from './reducers/featureFlags';
import generalAnnotationsReducer, {
  GeneralAnnotationsState,
} from './reducers/generalAnnotations';
import globalConfigurationReducer, {
  LetrusGlobalConfigurationState,
} from './reducers/globalConfiguration';
import learningPathReducer, {LearningPathState} from './reducers/learningPath';
import learningPathUserStepTimeSpentReducer, {
  LearningPathUserStepTimeSpentState,
} from './reducers/learningPathUserStepTimeSpent';
import notificationsReducer, {
  NotificationsState,
} from './reducers/notifications';
import pedagogicalContentReducer, {
  PedagogicalContentsState,
} from './reducers/pedagogicalContents';
import profileReducer, {ProfileState} from './reducers/profile';
import questionnairesReducer, {
  QuestionnairesState,
} from './reducers/questionnaires';
import reviewAnnotationsReducer, {
  ReviewAnnotationTypes,
} from './reducers/reviewAnnotations';
import s3Reducer, {S3State} from './reducers/s3';
import termsReducer, {TermsState} from './reducers/terms';
import testsReducer, {TestsState} from './reducers/tests';
import themesReducer, {ThemesState} from './reducers/themes';
import toggleCompletedTestReportReducer, {
  ToggleCompletedTestReportState,
} from './reducers/toggle';
import uiReducer, {UIState} from './reducers/ui';
import userReducer, {UserState} from './reducers/user';
import userActivitiesReducer, {
  UserActivitiesState,
} from './reducers/userActivities';
import userFeelingsReducer, {UserFeelingsState} from './reducers/userFeelings';
import writingPreparationReducer, {
  WritingPreparationState,
} from './reducers/writingPreparation';

export interface ApplicationState extends Map<any, any> {
  readonly authentication: AuthenticationState;
  readonly compositions: CompositionsState;
  readonly featureFlags: FeatureFlagsState;
  readonly generalAnnotations: GeneralAnnotationsState;
  readonly globalConfiguration: LetrusGlobalConfigurationState;
  readonly learningPath: LearningPathState;
  readonly notifications: NotificationsState;
  readonly reviewAnnotations: ReviewAnnotationTypes;
  readonly themes: ThemesState;
  readonly questionnaires: QuestionnairesState;
  readonly tests: TestsState;
  readonly pedagogicalContents: PedagogicalContentsState;
  readonly ui: UIState;
  readonly user: UserState;
  readonly s3: S3State;
  readonly toggleCompletedTestReport: ToggleCompletedTestReportState;
  readonly writingPreparation: WritingPreparationState;
  readonly userFeelings: UserFeelingsState;
  readonly learningPathUserStepTimeSpent: LearningPathUserStepTimeSpentState;
  readonly apiSync: ApiSyncState;
  readonly userActivities: UserActivitiesState;
  readonly profile: ProfileState;
  readonly terms: TermsState;
}

const appReducer = combineReducers({
  authentication: authenticationReducer,
  compositions: compositionsReducer,
  featureFlags: featureFlagsReducer,
  generalAnnotations: generalAnnotationsReducer,
  globalConfiguration: globalConfigurationReducer,
  learningPath: learningPathReducer,
  notifications: notificationsReducer,
  questionnaires: questionnairesReducer,
  pedagogicalContents: pedagogicalContentReducer,
  reviewAnnotations: reviewAnnotationsReducer,
  s3: s3Reducer,
  tests: testsReducer,
  themes: themesReducer,
  ui: uiReducer,
  user: userReducer,
  toggleCompletedTestReport: toggleCompletedTestReportReducer,
  writingPreparation: writingPreparationReducer,
  userFeelings: userFeelingsReducer,
  learningPathUserStepTimeSpent: learningPathUserStepTimeSpentReducer,
  apiSync: apiSyncReducer,
  userActivities: userActivitiesReducer,
  profile: profileReducer,
  terms: termsReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'RESET_STORE') {
    // Resetting the state to undefined will reinitialize all reducers
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
