import {fromJS, Map} from 'immutable';
import {AnyAction, Reducer} from 'redux';
import {call, put} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {
  fetchCurrentUserSuccess,
  logoutFailure as logoutUserFailure,
  logoutSuccess as logoutUserSuccess,
} from 'store/reducers/user';
import {ApplicationState} from 'store/rootReducer';
import {
  AccessRequest,
  accessService,
  LoginRequest,
  loginService,
  restorePasswordService,
  signupService,
} from 'store/services/authenticationService';
import {GoogleCredentials, googleService} from 'store/services/googleService';
import {LayersCredentials, layersService} from 'store/services/layersServices';
import {logoutService} from 'store/services/userServices';
import {action} from 'typesafe-actions';

// Actions types
export enum AuthenticationTypes {
  LOGIN_REQUEST = '@authentication/LOGIN_REQUEST',
  LAYERS_LOGIN_REQUEST = '@authentication/LAYERS_LOGIN_REQUEST',
  GOOGLE_LOGIN_REQUEST = '@authentication/GOOGLE_LOGIN_REQUEST',
  LOGIN_SUCCESS = '@authentication/LOGIN_SUCCESS',
  LOGIN_FAILURE = '@authentication/LOGIN_FAILURE',

  LOGOUT_REQUEST = '@authentication/LOGOUT_REQUEST',
  LOGOUT_SUCCESS = '@authentication/LOGOUT_SUCCESS',
  LOGOUT_FAILURE = '@authentication/LOGOUT_FAILURE',

  RESTORE_PASSWORD_REQUEST = '@authentication/RESTORE_PASSWORD_REQUEST',
  RESTORE_PASSWORD_SUCCESS = '@authentication/RESTORE_PASSWORD_SUCCESS',
  RESTORE_PASSWORD_FAILURE = '@authentication/RESTORE_PASSWORD_FAILURE',

  CLEAR_RESTORE_PASSWORD = '@authentication/CLEAR_RESTORE_PASSWORD',

  CLEAR_STATE = '@authentication/CLEAR_STATE',

  SIGNUP_REQUEST = '@authentication/SIGNUP_REQUEST',
  SIGNUP_SUCCESS = '@authentication/SIGNUP_SUCCESS',
  SIGNUP_FAILURE = '@authentication/SIGNUP_FAILURE',

  ACCESS_REQUEST = '@authentication/ACCESS_REQUEST',
  ACCESS_SUCCESS = '@authentication/ACCESS_SUCCESS',
  ACCESS_FAILURE = '@authentication/ACCESS_FAILURE',

  UPDATE_USER_PROFILE = '@authentication/UPDATE_USER_PROFILE',
}

// Data types
export interface Login {
  is_authenticated: boolean;
  user: string;
  user_roles: string[];
}

export interface SignupData {
  access_code: string;
  name: string;
  email: string;
  password: string;
  birthdate: string;
}

export interface SignupResult {
  message: string;
  username: string;
}

// State type
export interface AuthenticationState extends Map<any, any> {
  readonly data: ImmutableMap<Login> | undefined;
  readonly isLoadingLogout: boolean;
  readonly loading: boolean;
  readonly error: boolean;
  readonly isLoadingLogin: boolean;
  readonly isLoadingRestorePassword: boolean;
  readonly restorePasswordError: boolean;
  readonly restorePasswordMessage: string;
  readonly isLoadingSignup: boolean;
  readonly signupError: boolean;
  readonly signupResult: SignupResult;
  readonly signupFailureMessages: Record<string, any>;
  readonly loginFailureMessage: string;
}

export interface LoginActionProps extends LoginRequest {
  nextRoute?: string;
}

// Login actions
export const clearAuthenticationStore = () =>
  action(AuthenticationTypes.CLEAR_STATE);

export const layersLoginRequest = (data: LayersCredentials) =>
  action(AuthenticationTypes.LAYERS_LOGIN_REQUEST, data);

export const googleLoginRequest = (data: GoogleCredentials) =>
  action(AuthenticationTypes.GOOGLE_LOGIN_REQUEST, data);

export const loginUserRequest = (data: LoginActionProps) =>
  action(AuthenticationTypes.LOGIN_REQUEST, data);

export const loginSuccess = (data: {token: string}) =>
  action(AuthenticationTypes.LOGIN_SUCCESS, {data});

export const loginFailure = (data) => {
  return action(AuthenticationTypes.LOGIN_FAILURE, {data});
};

// Logout actions
export const logoutUserRequest = () =>
  action(AuthenticationTypes.LOGOUT_REQUEST);

export const logoutSuccess = () => action(AuthenticationTypes.LOGOUT_SUCCESS);

export const logoutFailure = () => action(AuthenticationTypes.LOGOUT_FAILURE);

export const resetStore = () => action('RESET_STORE');

// Restore Password actions
export const restorePasswordRequest = (data: {email: string}) =>
  action(AuthenticationTypes.RESTORE_PASSWORD_REQUEST, {data});

export const restorePasswordSuccess = (data) =>
  action(AuthenticationTypes.RESTORE_PASSWORD_SUCCESS, {data});

export const restorePasswordFailure = (data) =>
  action(AuthenticationTypes.RESTORE_PASSWORD_FAILURE, {data});

export const clearRestorePassword = () =>
  action(AuthenticationTypes.CLEAR_RESTORE_PASSWORD);

// Signup Actions
export const signupRequest = (data: SignupData) =>
  action(AuthenticationTypes.SIGNUP_REQUEST, {data});

export const signupSuccess = (data) =>
  action(AuthenticationTypes.SIGNUP_SUCCESS, {data});

export const signupFailure = (data) =>
  action(AuthenticationTypes.SIGNUP_FAILURE, {data});

// Access Actions
export const accessUserRequest = (data: AccessRequest) =>
  action(AuthenticationTypes.ACCESS_REQUEST, data);

export const accessSuccess = (data) =>
  action(AuthenticationTypes.ACCESS_SUCCESS, {data});

export const accessFailure = () => action(AuthenticationTypes.ACCESS_FAILURE);

export const updateUserProfile = (data) =>
  action(AuthenticationTypes.UPDATE_USER_PROFILE, {data});

// Sagas

export function* layersLoginUser(action: AnyAction) {
  try {
    const response = yield call(layersService, action.payload);
    yield put(fetchCurrentUserSuccess(response.data));
    yield put(loginSuccess(response.data));
  } catch (err: any) {
    yield put(
      loginFailure({
        message: err?.response?.data?.non_field_errors?.[0],
      }),
    );
  }
}

export function* googleLoginUser(action: AnyAction) {
  try {
    const response = yield call(googleService, action.payload);
    yield put(fetchCurrentUserSuccess(response?.data));
    yield put(loginSuccess(response?.data));
  } catch (err: any) {
    yield put(
      loginFailure({
        message: err?.response?.data?.[0],
      }),
    );
  }
}

export function* loginUser(action: AnyAction) {
  try {
    const response = yield call(loginService, action.payload);
    yield put(fetchCurrentUserSuccess(response.data));
    yield put(loginSuccess(response.data));
  } catch (err: any) {
    yield put(
      loginFailure({
        message: err?.response?.data?.non_field_errors?.[0],
      }),
    );
  }
}

export function* logoutUser() {
  try {
    yield call(logoutService);
    yield put(logoutUserSuccess());
    yield put(logoutSuccess());
  } catch (err) {
    yield put(logoutUserFailure());
    yield put(logoutFailure());
  }
}

export function* restorePassword(action: AnyAction) {
  try {
    const response = yield call(restorePasswordService, action.payload.data);
    yield put(restorePasswordSuccess(response.data));
  } catch (err: any) {
    yield put(
      restorePasswordFailure(err.response.data as Record<string, string[]>),
    );
  }
}

export function* signup(action: AnyAction) {
  try {
    const respose = yield call(signupService, action.payload.data);
    yield put(signupSuccess(respose.data));
  } catch (err) {
    yield put(signupFailure(err));
  }
}

export function* accessUser(action: AnyAction) {
  try {
    const response = yield call(accessService, action.payload);
    yield put(fetchCurrentUserSuccess(response.data));
    yield put(updateUserProfile(response.data));
    yield put(accessSuccess(response.data));
  } catch (error) {
    yield put(accessFailure());
  }
}

// Initial state
export const INITIAL_STATE: AuthenticationState = fromJS({
  data: fromJS({}),
  error: false,
  loading: false,
  isLoadingLogout: false,
  isLoadingLogin: false,
  isLoadingUserAccess: false,
  isLoadingRestorePassword: false,
  restorePasswordError: false,
  restorePasswordMessage: '',
  isLoadingSignup: false,
  signupResult: {},
  signupError: false,
  signupFailureMessages: {},
  userAccessError: false,
  loginFailureMessage: '',
});

// Selectors
const authenticationSelector = (state: ApplicationState) =>
  state.get('authentication');

export const getAuthenticationError = createSelector(
  authenticationSelector,
  (authentication: AuthenticationState) => authentication.get('error'),
);

export const getAuthenticationData = createSelector(
  authenticationSelector,
  (authentication: AuthenticationState) => authentication.get('data'),
);

export const isAuthed = createSelector(
  authenticationSelector,
  (authentication: AuthenticationState) =>
    authentication.getIn(['data', 'is_authenticated']),
);

export const isStaff = createSelector(
  authenticationSelector,
  (authentication: AuthenticationState) =>
    authentication.getIn(['data', 'user', 'is_staff']),
);

export const isAdmin = createSelector(
  authenticationSelector,
  (authentication: AuthenticationState) =>
    authentication.getIn(['data', 'user', 'is_superuser']),
);

export const isLoadingLogout = createSelector(
  authenticationSelector,
  (auth: AuthenticationState) => auth.get('isLoadingLogout'),
);

export const isLoadingLogin = createSelector(
  authenticationSelector,
  (auth: AuthenticationState) => auth.get('isLoadingLogin'),
);

export const isLoadingRestorePassword = createSelector(
  authenticationSelector,
  (auth: AuthenticationState) => auth.get('isLoadingRestorePassword'),
);

export const isLoadingUserAccess = createSelector(
  authenticationSelector,
  (auth: AuthenticationState) => auth.get('isLoadingUserAccess'),
);

export const restorePasswordError = createSelector(
  authenticationSelector,
  (auth: AuthenticationState) => auth.get('restorePasswordError'),
);

export const restorePasswordMessage = createSelector(
  authenticationSelector,
  (auth: AuthenticationState) => auth.get('restorePasswordMessage'),
);

export const isLoadingSignup = createSelector(
  authenticationSelector,
  (auth: AuthenticationState) => auth.get('isLoadingSignup'),
);

export const signupError = createSelector(
  authenticationSelector,
  (auth: AuthenticationState) => auth.get('signupError'),
);

export const getUserAccessError = createSelector(
  authenticationSelector,
  (auth: AuthenticationState) => auth.get('userAccessError'),
);

export const getSignupResult = createSelector(
  authenticationSelector,
  (auth: AuthenticationState) => auth.get('signupResult'),
);

export const getSignupFailureMessages = createSelector(
  authenticationSelector,
  (auth: AuthenticationState) => auth.get('signupFailureMessages'),
);

export const getLoginFailureMessage = createSelector(
  authenticationSelector,
  (auth: AuthenticationState) => auth.get('loginFailureMessage'),
);

// Reducer
const reducer: Reducer<AuthenticationState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case AuthenticationTypes.LOGIN_REQUEST:
    case AuthenticationTypes.LAYERS_LOGIN_REQUEST:
    case AuthenticationTypes.GOOGLE_LOGIN_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('isLoadingLogin', true),
      );

    case AuthenticationTypes.LOGIN_SUCCESS:
      return state.withMutations((prevState) => {
        return prevState
          .set('isLoadingLogin', false)
          .set('error', false)
          .set('data', fromJS(action.payload.data));
      });

    case AuthenticationTypes.LOGIN_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingLogin', false)
          .set('error', true)
          .set('data', fromJS({}))
          .set('loginFailureMessage', action.payload.data?.message),
      );

    case AuthenticationTypes.LOGOUT_REQUEST:
      return state.withMutations((prevState) => {
        return prevState.set('isLoadingLogout', true);
      });

    case AuthenticationTypes.LOGOUT_SUCCESS:
      return INITIAL_STATE;

    case AuthenticationTypes.LOGOUT_FAILURE:
      return INITIAL_STATE;

    case AuthenticationTypes.RESTORE_PASSWORD_REQUEST:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingRestorePassword', true)
          .set('restorePasswordError', false),
      );

    case AuthenticationTypes.RESTORE_PASSWORD_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingRestorePassword', false)
          .set('restorePasswordError', false)
          .set('restorePasswordMessage', action.payload.data?.message),
      );

    case AuthenticationTypes.RESTORE_PASSWORD_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingRestorePassword', false)
          .set('restorePasswordError', true)
          .set('restorePasswordMessage', action.payload.data.email[0]),
      );

    case AuthenticationTypes.CLEAR_RESTORE_PASSWORD:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingRestorePassword', false)
          .set('restorePasswordError', false)
          .set('restorePasswordMessage', undefined),
      );

    case AuthenticationTypes.SIGNUP_REQUEST:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingSignup', true)
          .set('signupResult', {})
          .set('signupError', false),
      );

    case AuthenticationTypes.SIGNUP_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingSignup', false)
          .set('signupResult', action.payload.data)
          .set('signupError', false),
      );

    case AuthenticationTypes.SIGNUP_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingSignup', false)
          .set('signupError', true)
          .set('signupFailureMessages', action.payload?.data?.response?.data),
      );

    case AuthenticationTypes.ACCESS_REQUEST:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingUserAccess', true)
          .set('userAccessError', false),
      );

    case AuthenticationTypes.ACCESS_SUCCESS:
      return state.withMutations((prevState) => {
        return prevState
          .set('isLoadingUserAccess', false)
          .set('userAccessError', false)
          .set('data', fromJS(action.payload.data));
      });

    case AuthenticationTypes.ACCESS_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingUserAccess', false)
          .set('userAccessError', true)
          .set('data', fromJS({})),
      );

    case AuthenticationTypes.UPDATE_USER_PROFILE:
      return state.withMutations((prevState) =>
        prevState.setIn(['data', 'user', 'user_profile'], action.payload.data),
      );

    case AuthenticationTypes.CLEAR_STATE:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default reducer;
