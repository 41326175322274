import {takeLatest} from 'redux-saga/effects';
import {
  fetchPedagogicalContentById,
  PedagogicalContentsTypes,
} from 'store/reducers/pedagogicalContents';

const pedagogicalContentSagas = [
  takeLatest(
    PedagogicalContentsTypes.FETCH_PEDAGOGICAL_CONTENT_BY_ID_REQUEST,
    fetchPedagogicalContentById,
  ),
];

export default pedagogicalContentSagas;
