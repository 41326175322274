import {takeLatest} from 'redux-saga/effects';
import {
  fetchNotifications,
  NotificationsTypes,
  updateNotificationById,
} from '../reducers/notifications';

const notificationSagas = [
  takeLatest(NotificationsTypes.FETCH_REQUEST, fetchNotifications),
  takeLatest(NotificationsTypes.UPDATE_BY_ID_REQUEST, updateNotificationById),
];

export default notificationSagas;
