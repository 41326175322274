import {AnyAction, Reducer} from 'redux';
import {call, put, StrictEffect} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {ApplicationState} from 'store/rootReducer';
import {
  createWritingPreparationService,
  fetchWritingPreparationService,
  finishWritingPreparationService,
  updateWritingPreparationService,
  WritingPreparationData,
} from 'store/services/writingPreparation';
import {action} from 'typesafe-actions';
import {StoreStatus} from 'utils/types/store';

// Action types
export enum WritingPreparationTypes {
  FETCH_WRITING_PREPARATION_REQUEST = '@writingPreparation/FETCH_WRITING_PREPARATION_REQUEST',
  FETCH_WRITING_PREPARATION_SUCCESS = '@writingPreparation/FETCH_WRITING_PREPARATION_SUCCESS',
  FETCH_WRITING_PREPARATION_FAILURE = '@writingPreparation/FETCH_WRITING_PREPARATION_FAILURE',

  CREATE_WRITING_PREPARATION_REQUEST = '@writingPreparation/CREATE_WRITING_PREPARATION_REQUEST',
  CREATE_WRITING_PREPARATION_SUCCESS = '@writingPreparation/CREATE_WRITING_PREPARATION_SUCCESS',
  CREATE_WRITING_PREPARATION_FAILURE = '@writingPreparation/CREATE_WRITING_PREPARATION_FAILURE',

  UPDATE_WRITING_PREPARATION_REQUEST = '@writingPreparation/UPDATE_WRITING_PREPARATION_REQUEST',
  UPDATE_WRITING_PREPARATION_SUCCESS = '@writingPreparation/UPDATE_WRITING_PREPARATION_SUCCESS',
  UPDATE_WRITING_PREPARATION_FAILURE = '@writingPreparation/UPDATE_WRITING_PREPARATION_FAILURE',

  FINISH_WRITING_PREPARATION_REQUEST = '@writingPreparation/FINISH_WRITING_PREPARATION_REQUEST',
  FINISH_WRITING_PREPARATION_SUCCESS = '@writingPreparation/FINISH_WRITING_PREPARATION_SUCCESS',
  FINISH_WRITING_PREPARATION_FAILURE = '@writingPreparation/FINISH_WRITING_PREPARATION_FAILURE',
}

// State type
export interface WritingPreparationState {
  readonly writingPreparation?: WritingPreparationData;
  readonly writingPreparationRequestStatus: StoreStatus;
  readonly finishWritePreparationRequestStatus: StoreStatus;
  readonly updateWritePreparationRequestStatus: StoreStatus;
  readonly createWritePreparationRequestStatus: StoreStatus;
}

// FETCH writing preparation Actions
export const fetchWritingPreparationRequest = (
  compositionId: string | number,
) =>
  action(WritingPreparationTypes.FETCH_WRITING_PREPARATION_REQUEST, {
    compositionId,
  });

export const fetchWritingPreparationSuccess = (
  writingPreparation: WritingPreparationData,
) =>
  action(WritingPreparationTypes.FETCH_WRITING_PREPARATION_SUCCESS, {
    writingPreparation,
  });

export const fetchWritingPreparationFailure = () =>
  action(WritingPreparationTypes.FETCH_WRITING_PREPARATION_FAILURE);

// CREATE writing preparation Actions
export const createWritingPreparationRequest = (
  compositionId: string | number,
) =>
  action(WritingPreparationTypes.CREATE_WRITING_PREPARATION_REQUEST, {
    compositionId,
  });

export const createWritingPreparationSuccess = (
  writingPreparation: WritingPreparationData,
) =>
  action(WritingPreparationTypes.CREATE_WRITING_PREPARATION_SUCCESS, {
    writingPreparation,
  });

export const createWritingPreparationFailure = () =>
  action(WritingPreparationTypes.CREATE_WRITING_PREPARATION_FAILURE);

// UPDATE writing preparation Actions
export const updateWritingPreparationRequest = (
  compositionId: string | number,
  data: Partial<WritingPreparationData> = {},
) =>
  action(WritingPreparationTypes.UPDATE_WRITING_PREPARATION_REQUEST, {
    compositionId,
    data,
  });

export const updateWritingPreparationSuccess = (
  writingPreparation: WritingPreparationData,
) =>
  action(WritingPreparationTypes.UPDATE_WRITING_PREPARATION_SUCCESS, {
    writingPreparation,
  });

export const updateWritingPreparationFailure = () =>
  action(WritingPreparationTypes.UPDATE_WRITING_PREPARATION_FAILURE);

// FINISH writing preparation Actions
export const finishWritingPreparationRequest = (
  compositionId: string | number,
  data: Partial<WritingPreparationData> = {},
) =>
  action(WritingPreparationTypes.FINISH_WRITING_PREPARATION_REQUEST, {
    compositionId,
    data,
  });

export const finishWritingPreparationSuccess = (data: []) =>
  action(WritingPreparationTypes.FINISH_WRITING_PREPARATION_SUCCESS, {
    data,
  });

export const finishWritingPreparationFailure = () =>
  action(WritingPreparationTypes.FINISH_WRITING_PREPARATION_FAILURE);

// Sagas
export function* fetchWritingPreparation(
  action: AnyAction,
): Generator<StrictEffect, void, {data: WritingPreparationData}> {
  try {
    const response = yield call(
      fetchWritingPreparationService,
      action.payload.compositionId,
    );

    yield put(fetchWritingPreparationSuccess(response.data));
  } catch (error) {
    yield put(fetchWritingPreparationFailure());
  }
}

export function* createWritingPreparation(
  action: AnyAction,
): Generator<StrictEffect, void, {data: WritingPreparationData}> {
  try {
    const response = yield call(
      createWritingPreparationService,
      action.payload.compositionId,
    );

    yield put(createWritingPreparationSuccess(response.data));
  } catch (error) {
    yield put(createWritingPreparationFailure());
  }
}

export function* updateWritingPreparation(
  action: AnyAction,
): Generator<StrictEffect, void, {data: WritingPreparationData}> {
  try {
    const response = yield call(
      updateWritingPreparationService,
      action.payload.compositionId,
      action.payload.data,
    );

    yield put(updateWritingPreparationSuccess(response.data));
  } catch (error) {
    yield put(updateWritingPreparationFailure());
  }
}

export function* finishWritingPreparation(
  action: AnyAction,
): Generator<StrictEffect, void, {data: []}> {
  try {
    const response = yield call(
      finishWritingPreparationService,
      action.payload.compositionId,
    );

    yield put(finishWritingPreparationSuccess(response.data));
  } catch (error) {
    yield put(finishWritingPreparationFailure());
  }
}

// Initial State
export const INITIAL_STATE: WritingPreparationState = {
  writingPreparation: undefined,
  writingPreparationRequestStatus: {
    error: false,
    fulfilled: false,
    loading: false,
    posting: false,
  },
  finishWritePreparationRequestStatus: {
    error: false,
    fulfilled: false,
    loading: false,
    posting: false,
  },
  updateWritePreparationRequestStatus: {
    error: false,
    fulfilled: false,
    loading: false,
    posting: false,
  },
  createWritePreparationRequestStatus: {
    error: false,
    fulfilled: false,
    loading: false,
    posting: false,
  },
};

// Selectors
const writingPreparationSelector = (state: ApplicationState) =>
  state.get('writingPreparation') as WritingPreparationState;

export const getWritingPreparation = createSelector(
  writingPreparationSelector,
  (state) => state.writingPreparation,
);

export const getWritingPreparationRequestStatus = createSelector(
  writingPreparationSelector,
  (state) => state.writingPreparationRequestStatus,
);

export const getFinishWritePreparationRequestStatus = createSelector(
  writingPreparationSelector,
  (state) => state.finishWritePreparationRequestStatus,
);

export const getUpdateWritePreparationRequestStatus = createSelector(
  writingPreparationSelector,
  (state) => state.updateWritePreparationRequestStatus,
);

export const getCreateWritePreparationRequestStatus = createSelector(
  writingPreparationSelector,
  (state) => state.createWritePreparationRequestStatus,
);

// STATE
const reducer: Reducer<WritingPreparationState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case WritingPreparationTypes.FETCH_WRITING_PREPARATION_REQUEST: {
      return {
        ...state,
        writingPreparation: undefined,
        writingPreparationRequestStatus: {
          error: false,
          fulfilled: false,
          loading: true,
          posting: false,
        },
      };
    }

    case WritingPreparationTypes.FETCH_WRITING_PREPARATION_SUCCESS: {
      return {
        ...state,
        writingPreparation: action.payload.writingPreparation,
        writingPreparationRequestStatus: {
          error: false,
          fulfilled: true,
          loading: false,
          posting: false,
        },
      };
    }

    case WritingPreparationTypes.FETCH_WRITING_PREPARATION_FAILURE: {
      return {
        ...state,
        writingPreparationRequestStatus: {
          error: true,
          fulfilled: false,
          loading: false,
          posting: false,
        },
      };
    }

    case WritingPreparationTypes.CREATE_WRITING_PREPARATION_REQUEST: {
      return {
        ...state,
        writingPreparation: undefined,
        createWritePreparationRequestStatus: {
          error: false,
          fulfilled: false,
          loading: false,
          posting: true,
        },
      };
    }

    case WritingPreparationTypes.CREATE_WRITING_PREPARATION_SUCCESS: {
      return {
        ...state,
        writingPreparation: action.payload.writingPreparation,
        createWritePreparationRequestStatus: {
          error: false,
          fulfilled: true,
          loading: false,
          posting: false,
        },
        writingPreparationRequestStatus: {
          error: false,
          fulfilled: true,
          loading: false,
          posting: false,
        },
      };
    }

    case WritingPreparationTypes.CREATE_WRITING_PREPARATION_FAILURE: {
      return {
        ...state,
        writingPreparation: undefined,
        createWritePreparationRequestStatus: {
          error: true,
          fulfilled: false,
          loading: false,
          posting: false,
        },
      };
    }

    case WritingPreparationTypes.UPDATE_WRITING_PREPARATION_REQUEST: {
      return {
        ...state,
        updateWritePreparationRequestStatus: {
          error: false,
          fulfilled: false,
          loading: false,
          posting: true,
        },
      };
    }

    case WritingPreparationTypes.UPDATE_WRITING_PREPARATION_SUCCESS: {
      return {
        ...state,
        writingPreparation: action.payload.writingPreparation,
        updateWritePreparationRequestStatus: {
          error: false,
          fulfilled: true,
          loading: false,
          posting: false,
        },
      };
    }

    case WritingPreparationTypes.UPDATE_WRITING_PREPARATION_FAILURE: {
      return {
        ...state,
        updateWritePreparationRequestStatus: {
          error: true,
          fulfilled: false,
          loading: false,
          posting: false,
        },
      };
    }

    case WritingPreparationTypes.FINISH_WRITING_PREPARATION_REQUEST: {
      return {
        ...state,
        finishWritePreparationRequestStatus: {
          error: false,
          fulfilled: false,
          loading: false,
          posting: true,
        },
      };
    }

    case WritingPreparationTypes.FINISH_WRITING_PREPARATION_SUCCESS: {
      return {
        ...state,
        finishWritePreparationRequestStatus: {
          error: false,
          fulfilled: true,
          loading: false,
          posting: false,
        },
      };
    }

    case WritingPreparationTypes.FINISH_WRITING_PREPARATION_FAILURE: {
      return {
        ...state,
        finishWritePreparationRequestStatus: {
          error: true,
          fulfilled: false,
          loading: false,
          posting: false,
        },
      };
    }

    default:
      return state;
  }
};

export default reducer;
