import {fromJS, Map} from 'immutable';
import {AnyAction, Reducer} from 'redux';
import {createSelector} from 'reselect';
import {ApplicationState} from 'store/rootReducer';
import {action} from 'typesafe-actions';

// Action types

export enum ToggleCompletedTestReportTypes {
  CHANGE_TOGGLE_COMPLETED_TEST_REPORT_STATUS = '@toggleCompletedTestReport/CHANGE_TOGGLE_COMPLETED_TEST_REPORT_STATUS',
}

// State type
export type SelectedToggle =
  | 'performance'
  | 'proposal'
  | 'structure'
  | 'correction'
  | 'competencies'
  | 'annotations'
  | 'next-steps'
  | 'OCR'
  | 'last-review'
  | null;

export interface ToggleCompletedTestReportState extends Map<string, any> {
  readonly status: SelectedToggle;
}

// Actions
export const changeToggleCompletedTestReportStatus = (
  status: SelectedToggle,
): AnyAction =>
  action(
    ToggleCompletedTestReportTypes.CHANGE_TOGGLE_COMPLETED_TEST_REPORT_STATUS,
    {status},
  );

// Selectors
const toggleDataSelector = (state: ApplicationState) =>
  state.get('toggleCompletedTestReport');

export const getToggleStatus = createSelector(toggleDataSelector, (toggle) =>
  toggle.get('status'),
);

// Initial state
export const INITIAL_STATE: ToggleCompletedTestReportState = fromJS({
  status: null,
});

// Reducer
export const reducer: Reducer<ToggleCompletedTestReportState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case ToggleCompletedTestReportTypes.CHANGE_TOGGLE_COMPLETED_TEST_REPORT_STATUS:
      return state.set('status', action.payload.status);
    default:
      return state;
  }
};

export default reducer;
