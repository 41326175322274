import {AxiosResponse} from 'axios';
import {LearningPathStepTemp} from 'utils/types/learningPath';
import api from './api';

type UserLearningPathRoutes = '/details' | '';

export const fetchLearningPathService = (
  learningPathUserInstanceId?: number,
  stepOrder?: number,
  route: UserLearningPathRoutes = '',
) =>
  api(
    `/api/v1/new_learning_path_user_instances/${
      learningPathUserInstanceId ?? ''
    }${route}${learningPathUserInstanceId || route ? '/' : ''}`,
    {
      params: {
        step_number: stepOrder,
        limit: 60,
      },
    },
  );

export const fetchLearningPathDetailsService = (
  learningPathUserInstanceId: number,
  stepOrder?: number,
): Promise<AxiosResponse<LearningPathStepTemp>> =>
  api(
    `/api/v1/new_learning_path_user_instances/${learningPathUserInstanceId}/details/`,
    {
      params: {
        step_number: stepOrder,
      },
    },
  );

export const finishPedagogicalContentStepService = (
  learningPathUserInstanceId: number | string,
) =>
  api(
    `/api/v1/new_learning_path_user_instances/${learningPathUserInstanceId}/finish_pedagogical_step/`,
    {
      method: 'post',
    },
  );

export const viewLearningPathCSATService = (
  learningPathUserInstanceId: number | string,
  stepNumber?: number,
  started?: string,
) =>
  api(
    `/api/v1/new_learning_path_user_instances/${learningPathUserInstanceId}/view_opinion/`,
    {
      method: 'post',
      data: {step_number: stepNumber, started},
    },
  );
