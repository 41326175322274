import {AnyAction, Reducer} from 'redux';
import {call, put, StrictEffect} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {ApplicationState} from 'store/rootReducer';
import {
  createLearningPathUserStepTimeSpentService,
  fetchLearningPathUserStepTimeSpentService,
  updateLearningPathUserStepTimeSpentService,
} from 'store/services/learningPathUserStepTimeSpent';
import {action} from 'typesafe-actions';
import {StoreStatus} from 'utils/types/store';

// Action types

export enum LearningPathUserStepTimeSpentTypes {
  FETCH_LEARNING_PATH_USER_STEP_TIME_SPENT_REQUEST = '@learningPathUserStepTimeSpent/FETCH_LEARNING_PATH_USER_STEP_TIME_SPENT_REQUEST',
  FETCH_LEARNING_PATH_USER_STEP_TIME_SPENT_SUCCESS = '@learningPathUserStepTimeSpent/FETCH_LEARNING_PATH_USER_STEP_TIME_SPENT_SUCCESS',
  FETCH_LEARNING_PATH_USER_STEP_TIME_SPENT_FAILURE = '@learningPathUserStepTimeSpent/FETCH_LEARNING_PATH_USER_STEP_TIME_SPENT_FAILURE',

  CREATE_LEARNING_PATH_USER_STEP_TIME_SPENT_REQUEST = '@learningPathUserStepTimeSpent/CREATE_LEARNING_PATH_USER_STEP_TIME_SPENT_REQUEST',
  CREATE_LEARNING_PATH_USER_STEP_TIME_SPENT_SUCCESS = '@learningPathUserStepTimeSpent/CREATE_LEARNING_PATH_USER_STEP_TIME_SPENT_SUCCESS',
  CREATE_LEARNING_PATH_USER_STEP_TIME_SPENT_FAILURE = '@learningPathUserStepTimeSpent/CREATE_LEARNING_PATH_USER_STEP_TIME_SPENT_FAILURE',

  UPDATE_LEARNING_PATH_USER_STEP_TIME_SPENT_REQUEST = '@learningPathUserStepTimeSpent/UPDATE_LEARNING_PATH_USER_STEP_TIME_SPENT_REQUEST',
  UPDATE_LEARNING_PATH_USER_STEP_TIME_SPENT_SUCCESS = '@learningPathUserStepTimeSpent/UPDATE_LEARNING_PATH_USER_STEP_TIME_SPENT_SUCCESS',
  UPDATE_LEARNING_PATH_USER_STEP_TIME_SPENT_FAILURE = '@learningPathUserStepTimeSpent/UPDATE_LEARNING_PATH_USER_STEP_TIME_SPENT_FAILURE',
}

export interface LearningPathUserStepTimeSpentTemp {
  count: number;
  next: string;
  previous: string;
  results: LearningPathUserStepTimeSpentData[];
}

export interface LearningPathUserStepTimeSpentData {
  started_at?: string;
  finished_at?: string | null;
  learning_path_user_instance: number;
  step: number;
}

// State type
export interface LearningPathUserStepTimeSpentState {
  readonly learningPathUserStepTimeSpent?:
    | LearningPathUserStepTimeSpentTemp
    | undefined;
  readonly learningPathUserStepTimeSpentRequestStatus: StoreStatus;
}

// FETCH learning path user step time spent Actions

export const fetchLearningPathUserStepTimeSpentRequest = (
  learningPathUserInstance: number | string,
) => {
  return action(
    LearningPathUserStepTimeSpentTypes.FETCH_LEARNING_PATH_USER_STEP_TIME_SPENT_REQUEST,
    {learningPathUserInstance},
  );
};
export const fetchLearningPathUserStepTimeSpentSuccess = (
  learningPathUserStepTimeSpent: LearningPathUserStepTimeSpentData,
) =>
  action(
    LearningPathUserStepTimeSpentTypes.FETCH_LEARNING_PATH_USER_STEP_TIME_SPENT_SUCCESS,
    {
      learningPathUserStepTimeSpent,
    },
  );

export const fetchLearningPathUserStepTimeSpentFailure = () =>
  action(
    LearningPathUserStepTimeSpentTypes.FETCH_LEARNING_PATH_USER_STEP_TIME_SPENT_FAILURE,
  );

// CREATE learning path user step time spent Actions

export const createLearningPathUserStepTimeSpentRequest = (
  learningPathUserStepTimeSpent: LearningPathUserStepTimeSpentData,
) =>
  action(
    LearningPathUserStepTimeSpentTypes.CREATE_LEARNING_PATH_USER_STEP_TIME_SPENT_REQUEST,
    {
      learningPathUserStepTimeSpent,
    },
  );

export const createLearningPathUserStepTimeSpentSuccess = (
  learningPathUserStepTimeSpent: LearningPathUserStepTimeSpentData,
) =>
  action(
    LearningPathUserStepTimeSpentTypes.CREATE_LEARNING_PATH_USER_STEP_TIME_SPENT_SUCCESS,
    {
      learningPathUserStepTimeSpent,
    },
  );

export const createLearningPathUserStepTimeSpentFailure = () =>
  action(
    LearningPathUserStepTimeSpentTypes.CREATE_LEARNING_PATH_USER_STEP_TIME_SPENT_FAILURE,
  );

// UPDATE learning path user step time spent Action

export const updateLearningPathUserStepTimeSpentRequest = (
  learningPathUserStepTimeSpent: LearningPathUserStepTimeSpentData,
) =>
  action(
    LearningPathUserStepTimeSpentTypes.UPDATE_LEARNING_PATH_USER_STEP_TIME_SPENT_REQUEST,
    {
      learningPathUserStepTimeSpent,
    },
  );

export const updateLearningPathUserStepTimeSpentSuccess = (
  learningPathUserStepTimeSpent: LearningPathUserStepTimeSpentData,
) =>
  action(
    LearningPathUserStepTimeSpentTypes.UPDATE_LEARNING_PATH_USER_STEP_TIME_SPENT_SUCCESS,
    {
      learningPathUserStepTimeSpent,
    },
  );

export const updateLearningPathUserStepTimeSpentFailure = () =>
  action(
    LearningPathUserStepTimeSpentTypes.UPDATE_LEARNING_PATH_USER_STEP_TIME_SPENT_FAILURE,
  );

// Sagas
export function* fetchLearningPathUserStepTimeSpent(action: AnyAction) {
  try {
    const response = yield call(
      fetchLearningPathUserStepTimeSpentService,
      action.payload.learningPathUserInstance,
    );

    yield put(fetchLearningPathUserStepTimeSpentSuccess(response.data));
  } catch (error) {
    yield put(fetchLearningPathUserStepTimeSpentFailure());
  }
}

export function* createLearningPathUserStepTimeSpent(
  action: AnyAction,
): Generator<StrictEffect, void, {data: LearningPathUserStepTimeSpentData}> {
  try {
    const response = yield call(
      createLearningPathUserStepTimeSpentService,
      action.payload.learningPathUserStepTimeSpent,
    );

    yield put(createLearningPathUserStepTimeSpentSuccess(response.data));
  } catch (error) {
    yield put(createLearningPathUserStepTimeSpentFailure());
  }
}

export function* updateLearningPathUserStepTimeSpent(
  action: AnyAction,
): Generator<StrictEffect, void, {data: LearningPathUserStepTimeSpentData}> {
  try {
    const response = yield call(
      updateLearningPathUserStepTimeSpentService,
      action.payload.learningPathUserStepTimeSpent,
    );

    yield put(updateLearningPathUserStepTimeSpentSuccess(response.data));
  } catch (error) {
    yield put(updateLearningPathUserStepTimeSpentFailure());
  }
}

// Initial State
export const INITIAL_STATE: LearningPathUserStepTimeSpentState = {
  learningPathUserStepTimeSpent: undefined,
  learningPathUserStepTimeSpentRequestStatus: {
    error: false,
    fulfilled: false,
    loading: false,
    posting: false,
  },
};

// Selectors

const learningPathUserStepTimeSpentSelector = (state: ApplicationState) =>
  state.get(
    'learningPathUserStepTimeSpent',
  ) as LearningPathUserStepTimeSpentState;

export const getLearningPathUserStepTimeSpent = createSelector(
  learningPathUserStepTimeSpentSelector,
  (state) => state.learningPathUserStepTimeSpent,
);

export const getLearningPathUserStepTimeSpentStatus = createSelector(
  learningPathUserStepTimeSpentSelector,
  (state) => state.learningPathUserStepTimeSpentRequestStatus,
);

// STATE

const reducer: Reducer<LearningPathUserStepTimeSpentState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case LearningPathUserStepTimeSpentTypes.FETCH_LEARNING_PATH_USER_STEP_TIME_SPENT_REQUEST: {
      return {
        ...state,
        learningPathUserStepTimeSpentRequestStatus: {
          error: false,
          fulfilled: false,
          loading: true,
          posting: false,
        },
      };
    }

    case LearningPathUserStepTimeSpentTypes.FETCH_LEARNING_PATH_USER_STEP_TIME_SPENT_SUCCESS: {
      return {
        ...state,
        learningPathUserStepTimeSpent:
          action.payload.learningPathUserStepTimeSpent,
        learningPathUserStepTimeSpentRequestStatus: {
          error: false,
          fulfilled: true,
          loading: false,
          posting: false,
        },
      };
    }

    case LearningPathUserStepTimeSpentTypes.FETCH_LEARNING_PATH_USER_STEP_TIME_SPENT_FAILURE: {
      return {
        ...state,
        learningPathUserStepTimeSpentRequestStatus: {
          error: true,
          fulfilled: false,
          loading: false,
          posting: false,
        },
      };
    }

    case LearningPathUserStepTimeSpentTypes.CREATE_LEARNING_PATH_USER_STEP_TIME_SPENT_REQUEST: {
      return {
        ...state,
        learningPathUserStepTimeSpentRequestStatus: {
          error: false,
          fulfilled: false,
          loading: false,
          posting: true,
        },
      };
    }

    case LearningPathUserStepTimeSpentTypes.CREATE_LEARNING_PATH_USER_STEP_TIME_SPENT_SUCCESS: {
      return {
        ...state,
        learningPathUserStepTimeSpent:
          action.payload.learningPathUserStepTimeSpent,
        learningPathUserStepTimeSpentRequestStatus: {
          error: false,
          fulfilled: true,
          loading: false,
          posting: false,
        },
      };
    }

    case LearningPathUserStepTimeSpentTypes.CREATE_LEARNING_PATH_USER_STEP_TIME_SPENT_FAILURE: {
      return {
        ...state,
        learningPathUserStepTimeSpentRequestStatus: {
          error: true,
          fulfilled: false,
          loading: false,
          posting: false,
        },
      };
    }

    case LearningPathUserStepTimeSpentTypes.UPDATE_LEARNING_PATH_USER_STEP_TIME_SPENT_REQUEST: {
      return {
        ...state,
        learningPathUserStepTimeSpentRequestStatus: {
          error: false,
          fulfilled: false,
          loading: false,
          posting: true,
        },
      };
    }

    case LearningPathUserStepTimeSpentTypes.UPDATE_LEARNING_PATH_USER_STEP_TIME_SPENT_SUCCESS: {
      return {
        ...state,
        learningPathUserStepTimeSpent:
          action.payload.learningPathUserStepTimeSpent,
        learningPathUserStepTimeSpentRequestStatus: {
          error: false,
          fulfilled: true,
          loading: false,
          posting: false,
        },
      };
    }

    case LearningPathUserStepTimeSpentTypes.UPDATE_LEARNING_PATH_USER_STEP_TIME_SPENT_FAILURE: {
      return {
        ...state,
        learningPathUserStepTimeSpentRequestStatus: {
          error: true,
          fulfilled: false,
          loading: false,
          posting: false,
        },
      };
    }

    default:
      return state;
  }
};

export default reducer;
