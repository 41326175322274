import api from './api';

export const logoutService = () =>
  api(`/api/v1/authlogout/`, {
    method: 'post',
  });

export const refreshJwtTokenService = () =>
  api('/api/v1/auth/jwt-refresh/', {
    method: 'post',
    data: {
      token: localStorage.getItem('csrftoken'),
    },
  });

export const updateUserService = (userData: any) =>
  api(`/api/v1/users/${userData.id}/`, {
    method: 'patch',
    data: {
      ...userData,
    },
  });

export const updateProfileService = (userData: any) =>
  api(`/api/v1/user_profile/${userData.id}/`, {
    method: 'patch',
    data: {
      ...userData,
    },
  });

export const createProfileService = (userData: any) =>
  api(`/api/v1/user_profile/`, {
    method: 'post',
    data: {
      ...userData,
    },
  });

export const fetchCurrentUserService = () => api('/api/v1/me/');

export const fetchUserByIdService = (userId: number) =>
  api(`/api/v1/get_user/${userId}/`);

export const updateUserAddressService = (userData: any) =>
  api(`/api/v1/user_profile/update_address/`, {
    method: 'post',
    data: {
      ...userData,
    },
  });

export const updateStudentParentService = (studentParentData: any) =>
  api(`/api/v1/student_parent/${studentParentData.id}/`, {
    method: 'patch',
    data: {
      ...studentParentData,
    },
  });

export const createStudentParentService = (studentParentData: any) =>
  api(`/api/v1/student_parent/`, {
    method: 'post',
    data: {
      ...studentParentData,
    },
  });

export const pickBasicPlanService = () =>
  api('/api/v1/pick_basic_plan/', {
    method: 'post',
  });

export const runCheckoutService = (checkoutData: any) =>
  api('/api/v1/checkout/', {
    method: 'post',
    data: {
      ...checkoutData,
    },
  });

export const resetPasswordService = (
  userId: number | string,
  passwords: {
    current_password: string;
    new_password: string;
    new_password_re?: string;
  },
) =>
  api(`/api/v1/users/${userId}/reset_password/`, {
    method: 'post',
    data: passwords,
  });

export const updateUserInformationService = (userInformation: any) =>
  api(`/api/v1/iugu_iugureviewerfinancialaccounts/${userInformation.id}/`, {
    method: 'patch',
    data: {
      ...userInformation,
    },
  });

export const validateAccessCodeService = (data: {access_code: string}) =>
  api(`/check_access_code/`, {
    method: 'post',
    data,
  });

export const sendEmailInterestedUserService = (data: any) =>
  api('/api/v1/user_role/send_email_interested_user/', {
    method: 'post',
    data,
  });

export const sendUserOpinionForSurveysService = (data: any) =>
  api('/api/v1/surveys/', {
    method: 'post',
    data,
  });

export const updateStudentProfileService = (studentData: any) =>
  api(`/api/v1/user_profile/update_student_profile/?id=${studentData.id}`, {
    method: 'post',
    data: {
      ...studentData,
    },
  });
