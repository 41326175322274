import NewLogo from '../NewLogo';
import './styles.scss';

interface LoadingProps {
  show: boolean;
}

const Loading: React.FC<LoadingProps> = ({show}) => {
  return show ? (
    <div data-testid="loading" className="loading">
      <NewLogo isLoading={show} />
    </div>
  ) : null;
};

export default Loading;
