import api from './api';

export interface LayersCredentials {
  bridgeConnected: boolean;
  platform: string;
  payload: {
    layersPortalLocation: string;
    layersPortalInnerLocationBase: string;
    portal: {
      alias: string;
      title: string;
      icon: string;
      location: string;
      origins: string[];
      placement: string[];
      features: string[];
      action: string;
      target: string;
      appId: string;
      oauth: {
        enabled: boolean;
        scopes: string[];
        clientId: string;
      };
    };
  };
  session: string;
  userId: string;
  communityId: string;
  accountId: string;
  params: Record<string, unknown>;
  preferredLanguages: string[];
  preferredTimezone: string;
}

export const layersService = (layersCredentials: LayersCredentials) =>
  api(`/api/v1/layers/auth/`, {
    method: 'post',
    data: {
      user_id: layersCredentials.userId,
      session: layersCredentials.session,
      community_id: layersCredentials.communityId,
      platform: 'student',
    },
  });
