import {takeLatest} from 'redux-saga/effects';
import {
  fetchLearningPathById,
  fetchLearningPathList,
  fetchLearningPathStepByOrder,
  finishPedagogicalContentStep,
  LearningPathTypes,
  viewLearningPathCSAT,
} from 'store/reducers/learningPath';

const learningPathSagas = [
  takeLatest(
    LearningPathTypes.FINISH_PEDAGOGICAL_CONTENT_STEP_REQUEST,
    finishPedagogicalContentStep,
  ),
  takeLatest(
    LearningPathTypes.FETCH_LEARNING_PATH_LIST_REQUEST,
    fetchLearningPathList,
  ),
  takeLatest(
    LearningPathTypes.FETCH_LEARNING_PATH_REQUEST,
    fetchLearningPathById,
  ),
  takeLatest(
    LearningPathTypes.FETCH_LEARNING_PATH_STEP_REQUEST,
    fetchLearningPathStepByOrder,
  ),
  takeLatest(
    LearningPathTypes.VIEW_LEARNING_PATH_CSAT_REQUEST,
    viewLearningPathCSAT,
  ),
];

export default learningPathSagas;
