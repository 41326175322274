import api, {GetParams} from './api';

export const fetchNotificationsService = (params?: GetParams) =>
  api(
    `/api/v1/notification_logs/?channel=web&limit=20&ordering=-created&offset=${
      params && params.offset
    }`,
  );

export const updateNotificationByIdService = (notificationId: number) =>
  api(`/api/v1/notification_logs/${notificationId}/`, {
    method: 'patch',
    data: {
      received: new Date(),
    },
  });
