import {AxiosResponse} from 'axios';
import api from './api';

export interface Term {
  id: number;
  parameter: string;
  value: string;
  modified: string;
}

export const fetchTermsService = (): Promise<AxiosResponse<Term>> =>
  api(`/api/v1/terms/`);
