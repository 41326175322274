import {all} from 'redux-saga/effects';
import apiSyncSagas from './sagas/apiSync';
import authenticationSagas from './sagas/authentication';
import compositionSagas from './sagas/compositions';
import featureFlagSagas from './sagas/featureFlags';
import generalAnnotationSagas from './sagas/generalAnnotations';
import globalConfigurationSagas from './sagas/globalConfiguration';
import learningPathSagas from './sagas/learningPath';
import learningPathUserStepTimeSpentSagas from './sagas/learningPathUserStepTimeSpent';
import notificationSagas from './sagas/notifications';
import pedagogicalContentSagas from './sagas/pedagogicalContents';
import profileSagas from './sagas/profile';
import questionnaireSagas from './sagas/questionnaires';
import reviewAnnotationSagas from './sagas/reviewAnnotations';
import s3Sagas from './sagas/s3';
import termsSagas from './sagas/terms';
import testSagas from './sagas/tests';
import userActivitiesSagas from './sagas/userActivities';
import userFeelingsSagas from './sagas/userFeelings';
import userSagas from './sagas/users';
import writingPreparationSagas from './sagas/writingPreparation';

export default function* rootSaga() {
  yield all([
    ...authenticationSagas,
    ...compositionSagas,
    ...featureFlagSagas,
    ...generalAnnotationSagas,
    ...globalConfigurationSagas,
    ...learningPathSagas,
    ...notificationSagas,
    ...pedagogicalContentSagas,
    ...questionnaireSagas,
    ...reviewAnnotationSagas,
    ...s3Sagas,
    ...testSagas,
    ...userSagas,
    ...writingPreparationSagas,
    ...userFeelingsSagas,
    ...learningPathUserStepTimeSpentSagas,
    ...apiSyncSagas,
    ...userActivitiesSagas,
    ...profileSagas,
    ...termsSagas,
  ]);
}
