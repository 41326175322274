import {takeLatest} from 'redux-saga/effects';
import {
  fetchActivities,
  updateActivities,
  UserActivitiesActionTypes,
} from 'store/reducers/userActivities';

const userActivitiesSagas = [
  takeLatest(
    UserActivitiesActionTypes.FETCH_ACTIVITY_LIST_REQUEST,
    fetchActivities,
  ),
  takeLatest(
    UserActivitiesActionTypes.UPDATE_ACTIVITY_LIST_REQUEST,
    updateActivities,
  ),
];

export default userActivitiesSagas;
