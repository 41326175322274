import {takeLatest} from 'redux-saga/effects';
import {fetchScheduledActivities, TestsTypes} from '../reducers/tests';

const testSagas = [
  takeLatest(
    TestsTypes.FETCH_SCHEDULED_ACTIVITIES_REQUEST,
    fetchScheduledActivities,
  ),
];

export default testSagas;
