import {Utils} from '@letrustech/letrus-api-interfaces';
import {AxiosResponse} from 'axios';
import {ActivitiesByStatus} from 'utils/types/userActivities';
import api from './api';

export const fetchActivitiesService = (
  params?: Utils.GetParams,
): Promise<AxiosResponse<ActivitiesByStatus>> =>
  api('/api/v1/activities/', {
    params,
  });
