import {AnyAction} from 'redux';
import {call, put, StrictEffect} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {ApplicationState} from 'store/rootReducer';
import {fetchTermsService, Term} from 'store/services/termsServices';
import {StoreStatus} from 'utils/types/store';

export enum TermsTypes {
  FETCH_TERMS_REQUEST = '@terms/FETCH_TERMS_REQUEST',
  FETCH_TERMS_SUCCESS = '@terms/FETCH_TERMS_SUCCESS',
  FETCH_TERMS_FAILURE = '@terms/FETCH_TERMS_FAILURE',
}

export interface TermsState {
  readonly termsList: Term[];
  readonly termsListRequestStatus: StoreStatus;
}

// actions
export const fetchTermsRequest = () => ({
  type: TermsTypes.FETCH_TERMS_REQUEST,
});

export const fetchTermsSuccess = (termsList: Term[]) => ({
  type: TermsTypes.FETCH_TERMS_SUCCESS,
  payload: {
    termsList,
  },
});

export const fetchTermsFailure = () => ({
  type: TermsTypes.FETCH_TERMS_FAILURE,
});

// sagas
export function* fetchTerms(): Generator<StrictEffect, void, any> {
  try {
    const response = yield call(fetchTermsService);
    yield put(fetchTermsSuccess(response.data));
  } catch (error) {
    yield put(fetchTermsFailure());
  }
}

// selectors
const termsSelector = (state: ApplicationState) =>
  state.get('terms') as TermsState;

export const getTerms = createSelector(
  termsSelector,
  (terms) => terms.termsList,
);

export const getTermsRequestStatus = createSelector(
  termsSelector,
  (terms) => terms.termsListRequestStatus,
);

// initial state
const INITIAL_STATE: TermsState = {
  termsList: [],
  termsListRequestStatus: {
    loading: false,
    posting: false,
    fulfilled: false,
    error: false,
  },
};

const reducer = (
  state: TermsState = INITIAL_STATE,
  action: AnyAction,
): TermsState => {
  switch (action.type) {
    case TermsTypes.FETCH_TERMS_REQUEST:
      return {
        ...state,
        termsListRequestStatus: {
          ...state.termsListRequestStatus,
          loading: true,
        },
      };

    case TermsTypes.FETCH_TERMS_SUCCESS:
      return {
        ...state,
        termsList: action.payload.termsList,
        termsListRequestStatus: {
          ...state.termsListRequestStatus,
          fulfilled: true,
          loading: false,
          error: false,
        },
      };

    case TermsTypes.FETCH_TERMS_FAILURE:
      return {
        ...state,
        termsListRequestStatus: {
          ...state.termsListRequestStatus,
          error: true,
          loading: false,
        },
      };

    default:
      return state;
  }
};

export default reducer;
