import {takeLatest} from 'redux-saga/effects';
import {
  createWritingPreparation,
  fetchWritingPreparation,
  finishWritingPreparation,
  updateWritingPreparation,
  WritingPreparationTypes,
} from 'store/reducers/writingPreparation';

const writingPreparationSagas = [
  takeLatest(
    WritingPreparationTypes.FETCH_WRITING_PREPARATION_REQUEST,
    fetchWritingPreparation,
  ),
  takeLatest(
    WritingPreparationTypes.CREATE_WRITING_PREPARATION_REQUEST,
    createWritingPreparation,
  ),
  takeLatest(
    WritingPreparationTypes.UPDATE_WRITING_PREPARATION_REQUEST,
    updateWritingPreparation,
  ),
  takeLatest(
    WritingPreparationTypes.FINISH_WRITING_PREPARATION_REQUEST,
    finishWritingPreparation,
  ),
];

export default writingPreparationSagas;
