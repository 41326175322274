export function getParagraphs(text) {
  const matches = text.match(/<p>(.*?)<\/p>/gim);

  // Verify if text has <p> tags
  if (matches) {
    return matches.map(function (value) {
      return mapParagraphsPositions(value.replace(/<\/?p>/gim, ''));
    });
  }

  return mapParagraphsPositions(text.split('\n').filter((value) => value));
}

export function mapParagraphsPositions(paragraphs) {
  let start = 0;
  let end = 0;

  return paragraphs.map((paragraph, i) => {
    end = start + paragraph.length;
    const resp = {
      boundariesTotal: [start, end],
      boundaries: [0, paragraph.length],
      paragraph: i,
      text: paragraph,
    };
    start = end + 1;
    return resp;
  });
}
