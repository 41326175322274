import Loading from 'components/Loading';
import {lazy, Suspense} from 'react';
import {render} from 'react-dom';
import {Provider} from 'react-redux';
import {register as registerServiceWorker} from './serviceWorkers/index';
import store from './store';

const App = lazy(() => import('./App'));

render(
  <Provider store={store}>
    <Suspense fallback={<Loading show />}>
      <App />
    </Suspense>
  </Provider>,
  document.getElementById('root'),
);

registerServiceWorker();
