import ReviewRate from 'components/ReviewRate';
import React, {CSSProperties} from 'react';
import SimpleTooltipOverlay from './SimpleTooltipOverlay';
import styles from './TooltipOverlay.module.scss';

export interface TooltipOverlayProps {
  entity: any;
  entityCategory?: any;
  competence?: string;
  score?: string;
  message?: string;
  className: string;
  rate?: {
    status?: 'like' | 'dislike' | 'default';
    onLike?(): void;
    onDislike?(): void;
  };
  style?: CSSProperties;
}

const TooltipOverlay: React.FC<TooltipOverlayProps> = ({
  className,
  competence,
  score,
  entity,
  message,
  rate,
  style,
}) => {
  const suggestions = (entity.suggestions || []).filter(
    (suggestion: any) => suggestion,
  );
  return (
    <SimpleTooltipOverlay
      competence={competence}
      score={score}
      className={className}
      style={style}
      entity={entity}
    >
      {message && message !== 'Comentário' && message !== 'Comentários' ? (
        <div className={styles.tooltipSection}>
          <div
            className={styles.tooltipSectionBody}
            dangerouslySetInnerHTML={{__html: message}}
          />
        </div>
      ) : null}

      {suggestions && suggestions.length ? (
        <div className={styles.tooltipSection}>
          <p className={styles.tooltipSectionTitle}>Sugestões</p>
          <div className={styles.tooltipSectionBody}>
            <ul>
              {entity.suggestions.map((suggestion: any, index: number) => (
                // eslint-disable-next-line react/no-array-index-key
                <li key={index}>{suggestion}</li>
              ))}
            </ul>
          </div>
        </div>
      ) : null}

      {entity.suggested_text ? (
        <div className={styles.tooltipSection}>
          <p className={styles.tooltipSectionTitle}>Sugestão</p>
          <div
            className={styles.tooltipSectionBody}
            dangerouslySetInnerHTML={{
              __html: entity.suggested_text,
            }}
          />
        </div>
      ) : null}

      {!message && !entity.suggested_text ? (
        <div className={styles.tooltipSection}>
          <div className={styles.tooltipSectionBody}>
            <p>Nenhum comentário ou sugestão</p>
          </div>
        </div>
      ) : null}

      {message && competence && !!rate && (
        <div className={styles.reviewRate}>
          <hr />
          <ReviewRate
            state={rate.status}
            onLike={rate.onLike}
            onDislike={rate.onDislike}
            outlined={false}
          />
        </div>
      )}
    </SimpleTooltipOverlay>
  );
};

export default TooltipOverlay;
