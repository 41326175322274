import {AnyAction, Reducer} from 'redux';
import {call, put} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {ApplicationState} from 'store/rootReducer';
import {
  ActivitySyncData,
  APIActivitySyncService,
} from 'store/services/apiSyncService';
import {action} from 'typesafe-actions';
import {StoreStatus} from 'utils/types/store';

// Action types
export enum ApiSyncTypes {
  ACTIVITY_SYNC_REQUEST = '@apiSync/ACTIVITY_SYNC_REQUEST',
  ACTIVITY_SYNC_SUCCESS = '@apiSync ACTIVITY_SYNC_SUCCESS',
  ACTIVITY_SYNC_FAILURE = '@apiSync/ACTIVITY_SYNC_FAILURE',
}

// State type
export interface ApiSyncState {
  readonly activitySyncRequestStatus: StoreStatus;
}

// API sync Actions
export const apiActivitySyncRequest = (activityData: ActivitySyncData) =>
  action(ApiSyncTypes.ACTIVITY_SYNC_REQUEST, {
    activityData,
  });

export const apiActivitySyncSuccess = () =>
  action(ApiSyncTypes.ACTIVITY_SYNC_SUCCESS);

export const apiActivitySyncFailure = () =>
  action(ApiSyncTypes.ACTIVITY_SYNC_FAILURE);

// Sagas
export function* apiActivitySync(action: AnyAction) {
  try {
    yield call(APIActivitySyncService, action.payload.activityData);
    yield put(apiActivitySyncSuccess());
  } catch (error) {
    yield put(apiActivitySyncFailure());
  }
}

// Initial State
export const INITIAL_STATE: ApiSyncState = {
  activitySyncRequestStatus: {
    error: false,
    fulfilled: false,
    loading: false,
    posting: false,
  },
};

// Selectors
const apiSyncSelector = (state: ApplicationState) =>
  state.get('apiSync') as ApiSyncState;

export const getApiActivitySyncRequestStatus = createSelector(
  apiSyncSelector,
  (state) => state.activitySyncRequestStatus,
);

// STATE
const reducer: Reducer<ApiSyncState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ApiSyncTypes.ACTIVITY_SYNC_REQUEST: {
      return {
        ...state,
        activitySyncRequestStatus: {
          error: false,
          fulfilled: false,
          loading: true,
          posting: false,
        },
      };
    }

    case ApiSyncTypes.ACTIVITY_SYNC_SUCCESS: {
      return {
        ...state,
        activitySyncRequestStatus: {
          error: false,
          fulfilled: true,
          loading: false,
          posting: false,
        },
      };
    }

    case ApiSyncTypes.ACTIVITY_SYNC_FAILURE: {
      return {
        ...state,
        activitySyncRequestStatus: {
          error: true,
          fulfilled: false,
          loading: false,
          posting: false,
        },
      };
    }
    default:
      return state;
  }
};

export default reducer;
