import {AxiosResponse} from 'axios';
import api from './api';

export interface WritingPreparationData {
  id: number;
  composition: number;
  markup_html_count: number;
  aids_markup_html: string;
  annotations: string;
  composition_instruction: string;
  created_at: string;
  updated_at: string;
  first_access: boolean;
  modified_at: string;
  status?: string;
}

export const fetchWritingPreparationService = (
  compositionId: number,
): Promise<AxiosResponse<WritingPreparationData>> =>
  api(`/api/v1/write_preparation/${compositionId}/`);

export const createWritingPreparationService = (
  compositionId: number,
): Promise<AxiosResponse<WritingPreparationData>> =>
  api(`/api/v1/write_preparation/`, {
    method: 'post',
    data: {composition: compositionId},
  });

export const updateWritingPreparationService = (
  compositionId: number,
  data: Partial<WritingPreparationData> = {},
): Promise<AxiosResponse<WritingPreparationData>> =>
  api(`/api/v1/write_preparation/${compositionId}/`, {
    method: 'patch',
    data,
  });

export const finishWritingPreparationService = (
  compositionId: number,
): Promise<AxiosResponse<[]>> =>
  api(`/api/v1/write_preparation/${compositionId}/finish_write_preparation/`, {
    method: 'post',
  });
