import {LetrusApi} from '@letrustech/letrus-api-interfaces';
import {fromJS, List, Map} from 'immutable';
import {Reducer} from 'redux';
import {call, put, StrictEffect} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {ApplicationState} from 'store/rootReducer';
import {action} from 'typesafe-actions';
import {TestCompositionExtended} from 'utils/types/compositions';
import {fetchScheduledActivitiesService} from '../../services/testServices';

// Action types
export enum TestsTypes {
  FETCH_SCHEDULED_ACTIVITIES_REQUEST = '@newTests/FETCH_SCHEDULED_ACTIVITIES_REQUEST',
  FETCH_SCHEDULED_ACTIVITIES_SUCCESS = '@newTests/FETCH_SCHEDULED_ACTIVITIES_SUCCESS',
  FETCH_SCHEDULED_ACTIVITIES_FAILURE = '@newTests/FETCH_SCHEDULED_ACTIVITIES_FAILURE',

  UPDATE_TEST = '@newTests/UPDATE_TEST',
}

// Data types

// State type
export interface TestsState extends Map<string, any> {
  readonly data: List<ImmutableMap<LetrusApi.Test>>;
  readonly loading: boolean;
  readonly error: number;
  readonly dataCount: number;
}

// Fetch actions
export const fetchScheduledActivitiesRequest = () =>
  action(TestsTypes.FETCH_SCHEDULED_ACTIVITIES_REQUEST);

export const fetchScheduledActivitiesSuccess = (data: LetrusApi.Test[]) =>
  action(TestsTypes.FETCH_SCHEDULED_ACTIVITIES_SUCCESS, data);

export const fetchScheduledActivitiesFailure = () =>
  action(TestsTypes.FETCH_SCHEDULED_ACTIVITIES_FAILURE);

export const updateTest = (
  testId: number,
  testAttributes: Partial<TestCompositionExtended>,
) => action(TestsTypes.UPDATE_TEST, {testId, testAttributes});

// Sagas
export function* fetchScheduledActivities(): Generator<
  StrictEffect,
  void,
  {data: LetrusApi.Test[]}
> {
  try {
    const response = yield call(fetchScheduledActivitiesService);
    yield put(fetchScheduledActivitiesSuccess(response.data));
  } catch (err) {
    yield put(fetchScheduledActivitiesFailure());
  }
}

// Selectors
const testsSelector = (state: ApplicationState) => state.get('tests');

export const getTests = createSelector(testsSelector, (schoolGroups) =>
  schoolGroups.get('data'),
);

export const isLoadingTests = createSelector(testsSelector, (tests) =>
  tests.get('loading'),
);

// Initial state
export const INITIAL_STATE: TestsState = fromJS({
  data: fromJS([]),
  error: false,
  loading: false,
  dataCount: 0,
});

// Reducer
export const reducer: Reducer<TestsState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TestsTypes.FETCH_SCHEDULED_ACTIVITIES_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('loading', true).set('error', false),
      );

    case TestsTypes.FETCH_SCHEDULED_ACTIVITIES_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .set('data', fromJS(action.payload)),
      );

    case TestsTypes.FETCH_SCHEDULED_ACTIVITIES_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', true)
          .set('data', fromJS([]))
          .set('dataCount', 0),
      );

    case TestsTypes.UPDATE_TEST:
      return state.withMutations((prevState) =>
        prevState.set(
          'data',
          prevState.get('data').map((test) => {
            if (test.get('id') === action.payload.testId) {
              return fromJS({...test, ...action.payload.testAttributes});
            }
            return fromJS(test);
          }),
        ),
      );

    default:
      return state;
  }
};

export default reducer;
