import api, {GetParams} from './api';

export const fetchScheduledActivitiesService = () =>
  api('/api/v1/tests/scheduled_activities/');

export const createTestService = (testData: any) =>
  api('/api/v1/tests/', {
    method: 'post',
    data: testData,
  });

export const fetchTestByIdService = (
  testId: number | string,
  params: GetParams,
) =>
  api(`/api/v1/tests/${testId}/`, {
    params,
  });

export const fetchLiveTestDataService = (testId: number) =>
  api(`/api/v1/tests/${testId}/realtime_report/`);

export const triggerLiveTestService = (testId: number | string) =>
  api(`/api/v1/tests/${testId}/trigger_realtime_report/`, {
    method: 'post',
  });

export const fetchTestGradeInfoService = (testId: number | string) =>
  api(`/api/v1/tests/${testId}/grade_info/`);

export const fetchTestIndicatorsInfoService = (testId: number | string) =>
  api(`/api/v1/tests/${testId}/indicators_info/`);

export const fetchTestStudentsInfoService = (testId: number | string) =>
  api(`/api/v1/tests/${testId}/students_info/`);

export const fetchEvolutionReportService = (params: GetParams) =>
  api(`/api/v1/tests/evolution/`, {
    params,
  });

export const sendTestsService = (testId: number | string, compositions: any) =>
  api(`/api/v1/tests/${testId}/finish_revision/`, {
    method: 'post',
    data: {compositions},
  });

export const fetchTestsBySchoolGroupService = (schoolGroupId: number) =>
  api(`/api/v1/school_groups_list/${schoolGroupId}/tests_without_children/`);

export const updateTestService = (testData: any) => {
  if (testData.toJS) {
    testData = testData.toJS();
  }

  return api(`/api/v1/tests/${testData.id}/`, {
    method: 'put',
    data: testData,
  });
};

export const deleteTestService = (testId: string | number) =>
  api(`/api/v1/tests/${testId}/`, {
    method: 'delete',
  });

export const fetchInProgressTestsService = (
  currentTime: string,
  query: any,
  extra: any,
) => {
  return api('/api/v1/tests_with_score/', {
    params: {
      start_datetime__lte: currentTime,
      end_datetime__gte: currentTime,
      limit: 10,
      type: 'inProgress',
      ...query,
      ...extra,
    } as any,
  });
};
export const fetchScheduledTestsService = (
  currentTime: string,
  query: any,
  extra: any,
) => {
  return api('/api/v1/tests_with_score/', {
    params: {
      start_datetime__gte: currentTime,
      limit: 10,
      type: 'scheduled',
      ...query,
      ...extra,
    } as any,
  });
};
export const fetchFinishedTestsService = (
  currentTime: string,
  query: any,
  extra: any,
) => {
  return api('/api/v1/tests_with_score/', {
    params: {
      end_datetime__lt: currentTime,
      limit: 10,
      type: 'finished',
      ...query,
      ...extra,
    } as any,
  });
};
