import {takeLatest} from 'redux-saga/effects';
import {
  addLikeInReviewAnnotation,
  ReviewAnnotationTypes,
} from 'store/reducers/reviewAnnotations';

const reviewAnnotationSagas = [
  takeLatest(ReviewAnnotationTypes.ADD_LIKE_REQUEST, addLikeInReviewAnnotation),
];

export default reviewAnnotationSagas;
