import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import styles from './ReviewRate.module.scss';

export type ReviewRateState = 'default' | 'like' | 'dislike';

export interface ReviewRateProps {
  state?: ReviewRateState;
  outlined?: boolean;
  onLike?(): void;
  onDislike?(): void;
}

function ReviewRate({
  state = 'default',
  outlined = true,
  onLike,
  onDislike,
}: ReviewRateProps): JSX.Element {
  return (
    <div
      data-testid="reviewRate"
      className={`${styles.wrapper} ${outlined && styles.outlined}`}
    >
      <p>O que você achou desta correção?</p>
      <div className={styles.actionButtons}>
        <button
          type="button"
          onClick={onLike}
          disabled={state !== 'default'}
          className={`${styles.like} ${state === 'like' && styles.active}`}
          data-testid="likeButton"
        >
          <FontAwesomeIcon icon={['fad', 'thumbs-up']} />
        </button>
        <button
          type="button"
          onClick={onDislike}
          disabled={state !== 'default'}
          className={`${styles.dislike} ${
            state === 'dislike' && styles.active
          }`}
          data-testid="dislikeButton"
        >
          <FontAwesomeIcon icon={['fad', 'thumbs-down']} />
        </button>
      </div>
    </div>
  );
}

export default ReviewRate;
