import {LetrusApi, Utils} from '@letrustech/letrus-api-interfaces';
import {fromJS, List, Map} from 'immutable';
import {AnyAction, Reducer} from 'redux';
import {call, put} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {action} from 'typesafe-actions';
import {
  fetchAidsService,
  fetchThemeByIdService,
  fetchThemesService,
} from '../../services/themeServices';

// Action types
export enum ThemesTypes {
  FETCH_REQUEST = '@themes/FETCH_REQUEST',
  FETCH_SUCCESS = '@themes/FETCH_SUCCESS',
  FETCH_FAILURE = '@themes/FETCH_FAILURE',

  FETCH_BY_ID_REQUEST = '@themes/FETCH_BY_ID_REQUEST',
  FETCH_BY_ID_SUCCESS = '@themes/FETCH_BY_ID_SUCCESS',
  FETCH_BY_ID_FAILURE = '@themes/FETCH_BY_ID_FAILURE',

  FETCH_AIDS_REQUEST = '@themes/FETCH_AIDS_REQUEST',
  FETCH_AIDS_SUCCESS = '@themes/FETCH_AIDS_SUCCESS',
  FETCH_AIDS_FAILURE = '@themes/FETCH_AIDS_FAILURE',
}

// Data types
export interface RepertoryContent {
  id: number;
  title: string;
  priority: number;
  link: string;
  content_html: string;
}

// State type
export interface ThemesState extends Map<string, any> {
  readonly data:
    | List<ImmutableMap<LetrusApi.CompositionInstruction>>
    | undefined;
  readonly loading: boolean;
  readonly error: number | undefined;
  readonly dataCount: number;

  readonly aids: List<ImmutableMap<LetrusApi.CompositionAid>> | undefined;
  readonly aidsCount: number;
  readonly isLoadingAids: boolean;

  readonly themeById: ImmutableMap<LetrusApi.CompositionInstruction>;
  readonly isLoadingThemeById: boolean;
}

// Fetch actions
export const fetchThemesRequest = (params?: Utils.GetParams) =>
  action(ThemesTypes.FETCH_REQUEST, params);

export const fetchThemesSuccess = (data: LetrusApi.CompositionInstruction[]) =>
  action(ThemesTypes.FETCH_SUCCESS, data);

export const fetchThemesFailure = () => action(ThemesTypes.FETCH_FAILURE);

export const fetchThemesByIdRequest = (themeId: number | string) =>
  action(ThemesTypes.FETCH_BY_ID_REQUEST, {themeId});

export const fetchThemesByIdSuccess = (
  data: LetrusApi.CompositionInstruction,
) => action(ThemesTypes.FETCH_BY_ID_SUCCESS, {data});

export const fetchThemesByIdFailure = () =>
  action(ThemesTypes.FETCH_BY_ID_FAILURE);

export const fetchAidsRequest = (params?: any) =>
  action(ThemesTypes.FETCH_AIDS_REQUEST, {params});

export const fetchAidsSuccess = (data: LetrusApi.CompositionAid[]) =>
  action(ThemesTypes.FETCH_AIDS_SUCCESS, {data});

export const fetchAidsFailure = () => action(ThemesTypes.FETCH_AIDS_FAILURE);

// Sagas
export function* fetchThemes(action: AnyAction) {
  try {
    const response = yield call(fetchThemesService, action.payload.params);
    yield put(fetchThemesSuccess(response.data));
  } catch (err) {
    yield put(fetchThemesFailure());
  }
}

export function* fetchThemesById(action: AnyAction) {
  try {
    const response = yield call(fetchThemeByIdService, action.payload.themeId);

    yield put(fetchThemesByIdSuccess(response.data));
  } catch (err) {
    yield put(fetchThemesByIdFailure());
  }
}

export function* fetchAids(action: AnyAction) {
  try {
    const response = yield call(fetchAidsService, action.payload.params);

    yield put(fetchAidsSuccess(response.data));
  } catch (err) {
    yield put(fetchAidsFailure());
  }
}

// Selectors
const themesDataSelector = (state) => state.get('themes');

export const getThemes = createSelector(themesDataSelector, (themes) =>
  themes.get('data'),
);

export const getAids = createSelector(themesDataSelector, (themes) =>
  themes.get('aids'),
);

export const isLoadingAids = createSelector(themesDataSelector, (themes) =>
  themes.get('isLoadingAids'),
);

export const getThemeById = createSelector(themesDataSelector, (themes) =>
  themes.get('themeById'),
);

export const isLoadingThemeById = createSelector(themesDataSelector, (themes) =>
  themes.get('isLoadingThemeById'),
);

export const getRepertoryContents = createSelector(
  themesDataSelector,
  (themes) => themes.getIn(['themeById', 'repertory_contents']),
);

export const isFundII = createSelector(
  themesDataSelector,
  (themes) =>
    themes.getIn(['themeById', 'genre', 'review_grid_name']) === 'Grade Única',
);

// Initial state
export const INITIAL_STATE: ThemesState = fromJS({
  data: fromJS([]),
  error: false,
  loading: false,
  dataCount: 0,
  aids: fromJS([]),
  aidsCount: 0,
  isLoadingAids: false,
  themeById: fromJS({}),
  isLoadingThemeById: false,
});

// Reducer
export const reducer: Reducer<ThemesState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case ThemesTypes.FETCH_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('loading', true).set('error', false),
      );

    case ThemesTypes.FETCH_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .set('data', fromJS(action.payload.data.results))
          .set('dataCount', action.payload.data.count),
      );

    case ThemesTypes.FETCH_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', true)
          .set('data', fromJS([]))
          .set('dataCount', 0),
      );

    case ThemesTypes.FETCH_BY_ID_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('isLoadingThemeById', true).set('error', false),
      );

    case ThemesTypes.FETCH_BY_ID_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingThemeById', false)
          .set('error', false)
          .set('themeById', fromJS(action.payload.data)),
      );

    case ThemesTypes.FETCH_BY_ID_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingThemeById', false)
          .set('error', true)
          .set('data', null),
      );

    case ThemesTypes.FETCH_AIDS_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('isLoadingAids', true).set('error', false),
      );

    case ThemesTypes.FETCH_AIDS_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingAids', false)
          .set('error', false)
          .set('aids', fromJS(action.payload.data.results))
          .set('aidsCount', action.payload.data.count),
      );

    case ThemesTypes.FETCH_AIDS_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingAids', false)
          .set('error', true)
          .set('aids', fromJS([]))
          .set('aidsCount', 0),
      );

    default:
      return state;
  }
};

export default reducer;
