import {fromJS, Map} from 'immutable';
import {AnyAction, Reducer} from 'redux';
import {call, put, StrictEffect} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {ApplicationState} from 'store/rootReducer';
import {action} from 'typesafe-actions';
import {uploadService} from '../../services/s3Services';

// Action types
export enum S3Types {
  UPLOAD_REQUEST = '@newS3/UPLOAD_REQUEST',
  UPLOAD_SUCCESS = '@newS3/UPLOAD_SUCCESS',
  UPLOAD_FAILURE = '@newS3/UPLOAD_FAILURE',
}

// Data types

// State type
export interface S3State extends Map<string, any> {
  readonly data: ImmutableMap<any> | undefined;
  readonly loading: boolean;
  readonly error: number | undefined;
  readonly dataCount: number;
}

// Fetch actions
export const uploadRequest = (file: any) =>
  action(S3Types.UPLOAD_REQUEST, {file});

export const uploadSuccess = (data: any) =>
  action(S3Types.UPLOAD_SUCCESS, {data});

export const uploadFailure = () => action(S3Types.UPLOAD_FAILURE);

// Sagas
export function* upload(action: AnyAction): Generator<StrictEffect, void, any> {
  try {
    const response = yield call(uploadService, action.payload.file);
    yield put(uploadSuccess(response.data));
  } catch (err) {
    yield put(uploadFailure());
  }
}

// Selectors
const s3DataSelector = (state: ApplicationState) => state.get('s3');

export const isLoadingS3 = createSelector(s3DataSelector, (s3: S3State) =>
  s3.get('loading'),
);

export const getS3Data = createSelector(s3DataSelector, (s3: S3State) =>
  s3.get('data'),
);

export const hasS3Error = createSelector(s3DataSelector, (s3: S3State) =>
  s3.get('error'),
);

// Initial state
export const INITIAL_STATE: S3State = fromJS({
  data: fromJS([]),
  error: false,
  loading: false,
  dataCount: 0,
});

// Reducer
export const reducer: Reducer<S3State | undefined> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case S3Types.UPLOAD_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('loading', true).set('error', false),
      );

    case S3Types.UPLOAD_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .set('data', fromJS(action.payload.data)),
      );

    case S3Types.UPLOAD_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', true),
      );

    default:
      return state;
  }
};

export default reducer;
