import api from 'store/services/api';

export const addLikeInReviewAnnotationService = (
  reviewAnnotationId: string | number,
  like: boolean,
) =>
  api(`/api/v1/review_annotations/${reviewAnnotationId}/like_info/`, {
    method: 'patch',
    data: {
      like_info: like,
    },
  });
