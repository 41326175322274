import {AnyAction, Reducer} from 'redux';
import {call, put, StrictEffect} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {ApplicationState} from 'store/rootReducer';
import {
  fetchLearningPathService,
  finishPedagogicalContentStepService,
  viewLearningPathCSATService,
} from 'store/services/learningPathServices';
import {action} from 'typesafe-actions';
import {LearningPathStepTemp, LearningPathTemp} from 'utils/types/learningPath';
import {StoreStatus} from 'utils/types/store';

// Action types
export enum LearningPathTypes {
  FETCH_LEARNING_PATH_LIST_REQUEST = '@learningPath/FETCH_LEARNING_PATH_LIST_REQUEST',
  FETCH_LEARNING_PATH_LIST_SUCCESS = '@learningPath/FETCH_LEARNING_PATH_LIST_SUCESS',
  FETCH_LEARNING_PATH_LIST_FAILURE = '@learningPath/FETCH_LEARNING_PATH_LIST_FAILURE',

  FETCH_LEARNING_PATH_REQUEST = '@learningPath/FETCH_LEARNING_PATH_REQUEST',
  FETCH_LEARNING_PATH_SUCCESS = '@learningPath/FETCH_LEARNING_PATH_SUCCESS',
  FETCH_LEARNING_PATH_FAILURE = '@learningPath/FETCH_LEARNING_PATH_FAILURE',

  FETCH_LEARNING_PATH_STEP_REQUEST = '@learningPath/FETCH_LEARNING_PATH_STEP_REQUEST',
  FETCH_LEARNING_PATH_STEP_SUCCESS = '@learningPath/FETCH_LEARNING_PATH_STEP_SUCCESS',
  FETCH_LEARNING_PATH_STEP_FAILURE = '@learningPath/FETCH_LEARNING_PATH_STEP_FAILURE',

  FINISH_PEDAGOGICAL_CONTENT_STEP_REQUEST = '@learningPath/FINISH_PEDAGOGICAL_CONTENT_STEP_REQUEST',
  FINISH_PEDAGOGICAL_CONTENT_STEP_SUCCESS = '@learningPath/FINISH_PEDAGOGICAL_CONTENT_STEP_SUCCESS',
  FINISH_PEDAGOGICAL_CONTENT_STEP_FAILURE = '@learningPath/FINISH_PEDAGOGICAL_CONTENT_STEP_FAILURE',

  VIEW_LEARNING_PATH_CSAT_REQUEST = '@learningPath/VIEW_LEARNING_PATH_OPINION_REQUEST',
  VIEW_LEARNING_PATH_CSAT_SUCCESS = '@learningPath/VIEW_LEARNING_PATH_OPINION_SUCCESS',
  VIEW_LEARNING_PATH_CSAT_FAILURE = '@learningPath/VIEW_LEARNING_PATH_OPINION_FAILURE',

  CLEAR_LEARNING_PATH_STORE = '@learningPath/CLEAR_LEARNING_PATH_STORE',
  CLEAR_LEARNING_PATH_STEP = '@learningPath/CLEAR_LEARNING_PATH_STEP',
}

// State type
export interface LearningPathState {
  readonly learningPathList: LearningPathTemp[];
  readonly learningPathListRequestStatus: StoreStatus;

  readonly learningPath?: LearningPathTemp;
  readonly learningPathRequestStatus: StoreStatus;

  readonly learningPathstep?: LearningPathStepTemp;
  readonly learningPathStepRequestStatus: StoreStatus;

  readonly viewLearningPathCSATRequestStatus: StoreStatus;
}

interface FetchLearningPathListParams {
  isUpdate?: boolean;
}

// Fetch User learning Paths actions
export const fetchLearningPathListRequest = ({
  isUpdate,
}: FetchLearningPathListParams = {}): AnyAction =>
  action(LearningPathTypes.FETCH_LEARNING_PATH_LIST_REQUEST, {isUpdate});

export const fetchLearningPathListSuccess = (
  learningPathsList: LearningPathTemp[],
): AnyAction =>
  action(LearningPathTypes.FETCH_LEARNING_PATH_LIST_SUCCESS, {
    learningPathsList,
  });

export const fetchLearningPathListFailure = (): AnyAction =>
  action(LearningPathTypes.FETCH_LEARNING_PATH_LIST_FAILURE);

// Fetch User learning Path by ID actions
export const fetchLearningPathByIdRequest = (
  learningPathUserInstanceId: number,
): AnyAction =>
  action(LearningPathTypes.FETCH_LEARNING_PATH_REQUEST, {
    learningPathUserInstanceId,
  });

export const fetchLearningPathByIdSuccess = (
  learningPath: LearningPathTemp,
): AnyAction =>
  action(LearningPathTypes.FETCH_LEARNING_PATH_SUCCESS, {
    learningPath,
  });

export const fetchLearningPathByIdFailure = (): AnyAction =>
  action(LearningPathTypes.FETCH_LEARNING_PATH_FAILURE);

// Fetch User learning Paths Step actions
export const fetchLearningPathStepByOrderRequest = (
  learningPathUserInstanceId: number,
  stepOrder?: number,
): AnyAction =>
  action(LearningPathTypes.FETCH_LEARNING_PATH_STEP_REQUEST, {
    learningPathUserInstanceId,
    stepOrder,
  });

export const fetchLearningPathStepByOrderSuccess = (
  learningPathStep: LearningPathStepTemp,
): AnyAction =>
  action(LearningPathTypes.FETCH_LEARNING_PATH_STEP_SUCCESS, {
    learningPathStep,
  });

export const fetchLearningPathStepByOrderFailure = (): AnyAction =>
  action(LearningPathTypes.FETCH_LEARNING_PATH_STEP_FAILURE);

// pedagogical content step finish action
export const finishPedagogicalContentStepRequest = (
  learningPathUserInstanceId: number | string,
) =>
  action(LearningPathTypes.FINISH_PEDAGOGICAL_CONTENT_STEP_REQUEST, {
    learningPathUserInstanceId,
  });

export const finishPedagogicalContentStepSuccess = () =>
  action(LearningPathTypes.FINISH_PEDAGOGICAL_CONTENT_STEP_SUCCESS);

export const finishPedagogicalContentStepFailure = (data) => {
  return action(LearningPathTypes.FINISH_PEDAGOGICAL_CONTENT_STEP_FAILURE, {
    data,
  });
};

// view leaning path opinion action
export const viewLearningPathCSATRequest = (
  learningPathUserInstanceId: number | string,
  stepNumber?: number,
  started?: string,
) =>
  action(LearningPathTypes.VIEW_LEARNING_PATH_CSAT_REQUEST, {
    learningPathUserInstanceId,
    stepNumber,
    started,
  });

export const viewLearningPathCSATSuccess = () =>
  action(LearningPathTypes.VIEW_LEARNING_PATH_CSAT_SUCCESS);

export const viewLearningPathCSATFailure = () =>
  action(LearningPathTypes.VIEW_LEARNING_PATH_CSAT_FAILURE);

// clear the learning path store action
export const clearLearningPath = () =>
  action(LearningPathTypes.CLEAR_LEARNING_PATH_STORE);

// clear the learning path store action
export const clearLearningPathStep = () =>
  action(LearningPathTypes.CLEAR_LEARNING_PATH_STEP);

// Sagas
export function* fetchLearningPathList(): Generator<StrictEffect, void, any> {
  try {
    // TO-DO: type response after letrusAPI interfaces is updated
    const response = yield call(fetchLearningPathService);
    yield put(fetchLearningPathListSuccess(response.data.results));
  } catch (err) {
    yield put(fetchLearningPathListFailure());
  }
}

export function* fetchLearningPathById(
  action: AnyAction,
): Generator<StrictEffect, void, any> {
  try {
    const response = yield call(
      fetchLearningPathService,
      action.payload.learningPathUserInstanceId,
    );
    yield put(fetchLearningPathByIdSuccess(response.data));
  } catch (err) {
    yield put(fetchLearningPathByIdFailure());
  }
}

export function* fetchLearningPathStepByOrder(
  action: AnyAction,
): Generator<StrictEffect, void, any> {
  try {
    const response = yield call(
      fetchLearningPathService,
      action.payload.learningPathUserInstanceId,
      action.payload.stepOrder,
      '/details',
    );
    yield put(fetchLearningPathStepByOrderSuccess(response.data));
  } catch (err) {
    yield put(fetchLearningPathStepByOrderFailure());
  }
}

export function* finishPedagogicalContentStep(
  action: AnyAction,
): Generator<StrictEffect, void> {
  try {
    yield call(
      finishPedagogicalContentStepService,
      action.payload.learningPathUserInstanceId,
    );

    yield put(finishPedagogicalContentStepSuccess());
  } catch (error) {
    const err = error as any;
    yield put(finishPedagogicalContentStepFailure(err?.response?.data));
  }
}

export function* viewLearningPathCSAT(
  action: AnyAction,
): Generator<StrictEffect, void> {
  try {
    yield call(
      viewLearningPathCSATService,
      action.payload.learningPathUserInstanceId,
      action.payload.stepNumber,
      action.payload.started,
    );

    yield put(viewLearningPathCSATSuccess());
  } catch (err) {
    yield put(viewLearningPathCSATFailure());
  }
}

// Selectors
const learningPathDataSelector = (state: ApplicationState) =>
  state.get('learningPath') as LearningPathState;

export const getLearningPathList = createSelector(
  learningPathDataSelector,
  (learningPathStore) => learningPathStore.learningPathList,
);

export const getLearningPathListRequestStatus = createSelector(
  learningPathDataSelector,
  (learningPathStore) => learningPathStore.learningPathListRequestStatus,
);

export const getLearningPathStep = createSelector(
  learningPathDataSelector,
  (learningPathStore) => learningPathStore.learningPathstep,
);

export const getLearningPathStepRequestStatus = createSelector(
  learningPathDataSelector,
  (learningPathStore) => learningPathStore.learningPathStepRequestStatus,
);

export const getLearningPath = createSelector(
  learningPathDataSelector,
  (learningPathStore) => learningPathStore.learningPath,
);

export const getLearningPathRequestStatus = createSelector(
  learningPathDataSelector,
  (learningPathStore) => learningPathStore.learningPathRequestStatus,
);

export const getViewLearningPathOpinionRequestStatus = createSelector(
  learningPathDataSelector,
  (learningPathStore) => learningPathStore.viewLearningPathCSATRequestStatus,
);

// Initial state
export const INITIAL_STATE: LearningPathState = {
  learningPathList: [],
  learningPathListRequestStatus: {
    fulfilled: false,
    loading: false,
    error: false,
    posting: false,
  },

  learningPath: undefined,
  learningPathRequestStatus: {
    fulfilled: false,
    loading: false,
    error: false,
    posting: false,
  },

  learningPathstep: undefined,
  learningPathStepRequestStatus: {
    fulfilled: false,
    loading: false,
    error: false,
    posting: false,
    errorMessage: '',
  },

  viewLearningPathCSATRequestStatus: {
    fulfilled: false,
    loading: false,
    error: false,
    posting: false,
  },
};

// Reducer
export const reducer: Reducer<LearningPathState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case LearningPathTypes.FETCH_LEARNING_PATH_LIST_REQUEST:
      return {
        ...state,
        learningPathListRequestStatus: {
          loading: !action.payload?.isUpdate ?? true,
          fulfilled: false,
          error: false,
          posting: false,
        },
      };

    case LearningPathTypes.FETCH_LEARNING_PATH_LIST_SUCCESS:
      return {
        ...state,
        learningPathList: action.payload.learningPathsList,
        learningPathListRequestStatus: {
          loading: false,
          fulfilled: true,
          error: false,
          posting: false,
        },
      };

    case LearningPathTypes.FETCH_LEARNING_PATH_LIST_FAILURE:
      return {
        ...state,
        learningPathListRequestStatus: {
          loading: false,
          fulfilled: false,
          error: true,
          posting: false,
        },
      };

    case LearningPathTypes.FETCH_LEARNING_PATH_REQUEST:
      return {
        ...state,
        learningPathRequestStatus: {
          loading: true,
          fulfilled: false,
          error: false,
          posting: false,
        },
      };

    case LearningPathTypes.FETCH_LEARNING_PATH_SUCCESS:
      return {
        ...state,
        learningPath: action.payload.learningPath,
        learningPathRequestStatus: {
          loading: false,
          fulfilled: true,
          error: false,
          posting: false,
        },
      };

    case LearningPathTypes.FETCH_LEARNING_PATH_FAILURE:
      return {
        ...state,
        learningPathRequestStatus: {
          loading: false,
          fulfilled: false,
          error: true,
          posting: false,
        },
      };

    case LearningPathTypes.FETCH_LEARNING_PATH_STEP_REQUEST:
      return {
        ...state,
        learningPathStepRequestStatus: {
          loading: true,
          fulfilled: false,
          error: false,
          posting: false,
        },
      };

    case LearningPathTypes.FETCH_LEARNING_PATH_STEP_SUCCESS:
      return {
        ...state,
        learningPathstep: action.payload.learningPathStep,
        learningPathStepRequestStatus: {
          loading: false,
          fulfilled: true,
          error: false,
          posting: false,
        },
      };

    case LearningPathTypes.FETCH_LEARNING_PATH_STEP_FAILURE:
      return {
        ...state,
        learningPathStepRequestStatus: {
          loading: false,
          fulfilled: false,
          error: true,
          posting: false,
        },
      };

    case LearningPathTypes.FINISH_PEDAGOGICAL_CONTENT_STEP_REQUEST:
      return {
        ...state,
        learningPathStepRequestStatus: {
          loading: false,
          fulfilled: false,
          error: false,
          posting: true,
        },
      };

    case LearningPathTypes.FINISH_PEDAGOGICAL_CONTENT_STEP_SUCCESS:
      return {
        ...state,
        learningPathStepRequestStatus: {
          loading: false,
          fulfilled: true,
          error: false,
          posting: false,
        },
      };

    case LearningPathTypes.FINISH_PEDAGOGICAL_CONTENT_STEP_FAILURE:
      return {
        ...state,
        learningPathStepRequestStatus: {
          loading: false,
          fulfilled: false,
          error: true,
          posting: false,
          errorMessage: action?.payload?.data?.error,
        },
      };

    case LearningPathTypes.VIEW_LEARNING_PATH_CSAT_REQUEST:
      return {
        ...state,
        viewLearningPathCSATRequestStatus: {
          loading: false,
          fulfilled: false,
          error: false,
          posting: true,
        },
      };

    case LearningPathTypes.VIEW_LEARNING_PATH_CSAT_SUCCESS:
      return {
        ...state,
        viewLearningPathCSATRequestStatus: {
          loading: false,
          fulfilled: true,
          error: false,
          posting: false,
        },
      };

    case LearningPathTypes.VIEW_LEARNING_PATH_CSAT_FAILURE:
      return {
        ...state,
        viewLearningPathCSATRequestStatus: {
          loading: false,
          fulfilled: false,
          error: true,
          posting: false,
        },
      };

    case LearningPathTypes.CLEAR_LEARNING_PATH_STORE:
      return INITIAL_STATE;

    case LearningPathTypes.CLEAR_LEARNING_PATH_STEP:
      return {
        ...state,
        learningPathstep: undefined,
        learningPathStepRequestStatus: {
          loading: false,
          fulfilled: false,
          error: false,
          posting: false,
        },
      };

    default:
      return state;
  }
};

export default reducer;
