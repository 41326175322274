import {AxiosResponse} from 'axios';
import {LearningPathUserStepTimeSpentData} from 'store/reducers/learningPathUserStepTimeSpent';
import api from './api';

export const fetchLearningPathUserStepTimeSpentService = (
  learningPathUserInstance: number | string,
): Promise<AxiosResponse<LearningPathUserStepTimeSpentData>> => {
  return api(
    `/api/v1/learning_path_user_step_time_spent/?learning_path_user_instance_id=${learningPathUserInstance}`,
  );
};

export const createLearningPathUserStepTimeSpentService = (
  data: LearningPathUserStepTimeSpentData,
): Promise<AxiosResponse<LearningPathUserStepTimeSpentData>> => {
  return api(`/api/v1/learning_path_user_step_time_spent/`, {
    method: 'post',
    data,
  });
};

export const updateLearningPathUserStepTimeSpentService = (
  data: Partial<LearningPathUserStepTimeSpentData>,
): Promise<AxiosResponse<LearningPathUserStepTimeSpentData>> => {
  return api(`/api/v1/learning_path_user_step_time_spent/${data.step}/`, {
    method: 'patch',
    data,
  });
};
