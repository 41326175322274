import {
  AdminApiV1QuestionAttemptsPostRequest,
  QuestionAttempt,
  Questionnaire,
} from '@letrustech/letrus-api-interfaces/dist/interfaces/letrus_api';
import {AxiosResponse} from 'axios';
import {QuestionAnswer} from 'features/useQuestionnaire';
import {AnyAction, Reducer} from 'redux';
import {call, put, StrictEffect} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {ApplicationState} from 'store/rootReducer';
import {action} from 'typesafe-actions';
import {StoreStatus} from 'utils/types/store';
import {
  fetchQuestionAnswersByStudentService,
  fetchQuestionnaireAnswersByStepService,
  fetchQuestionnaireByIdService,
  postQuestionAnswerService,
} from '../../services/questionnaireServices';

export interface QuestionAttemptData {
  option?: number;
  feedback?: string;
}

// Action types
export enum QuestionnairesTypes {
  FETCH_QUESTIONNAIRE_BY_ID_REQUEST = '@questionnaires/FETCH_QUESTIONNAIRE_BY_ID_REQUEST',
  FETCH_QUESTIONNAIRE_BY_ID_SUCCESS = '@questionnaires/FETCH_QUESTIONNAIRE_BY_ID_SUCCESS',
  FETCH_QUESTIONNAIRE_BY_ID_FAILURE = '@questionnaires/FETCH_QUESTIONNAIRE_BY_ID_FAILURE',

  POST_QUESTION_ANSWER_REQUEST = '@questionnaires/POST_QUESTION_ANSWER_REQUEST',
  POST_QUESTION_ANSWER_SUCCESS = '@questionnaires/POST_QUESTION_ANSWER_SUCCESS',
  POST_QUESTION_ANSWER_FAILURE = '@questionnaires/POST_QUESTION_ANSWER_FAILURE',

  FETCH_QUESTIONNAIRE_ANSWERS_BY_STEP_REQUEST = '@questionnaires/FETCH_QUESTIONNAIRE_ANSWERS_BY_STEP_REQUEST',
  FETCH_QUESTIONNAIRE_ANSWERS_BY_STEP_SUCCESS = '@questionnaires/FETCH_QUESTIONNAIRE_ANSWERS_BY_STEP_SUCCESS',
  FETCH_QUESTIONNAIRE_ANSWERS_BY_STEP_FAILURE = '@questionnaires/FETCH_QUESTIONNAIRE_ANSWERS_BY_STEP_FAILURE',

  FETCH_QUESTIONNAIRE_ANSWERS_BY_STUDENT_REQUEST = '@questionnaires/FETCH_QUESTIONNAIRE_ANSWERS_BY_STUDENT_REQUEST',
  FETCH_QUESTIONNAIRE_ANSWERS_BY_STUDENT_SUCCESS = '@questionnaires/FETCH_QUESTIONNAIRE_ANSWERS_BY_STUDENT_SUCCESS',
  FETCH_QUESTIONNAIRE_ANSWERS_BY_STUDENT_FAILURE = '@questionnaires/FETCH_QUESTIONNAIRE_ANSWERS_BY_STUDENT_FAILURE',

  ClEAR_QUESTION_ATTEMPT = '@newQuestionnaires/ClEAR_QUESTION_ATTEMPT',
}

// State type
export interface QuestionnairesState {
  readonly questionnaire: Questionnaire;
  readonly questionnaireAnswers: QuestionAttempt[];
  readonly status: StoreStatus;
}

// Create actions
export const fetchQuestionnaireByIdRequest = (
  questionnaireId: string | number,
): AnyAction =>
  action(QuestionnairesTypes.FETCH_QUESTIONNAIRE_BY_ID_REQUEST, {
    questionnaireId,
  });

export const fetchQuestionnaireByIdSuccess = (data: Questionnaire): AnyAction =>
  action(QuestionnairesTypes.FETCH_QUESTIONNAIRE_BY_ID_SUCCESS, {data});

export const fetchQuestionnaireByIdFailure = (): AnyAction =>
  action(QuestionnairesTypes.FETCH_QUESTIONNAIRE_BY_ID_FAILURE);

export const postQuestionAnswerRequest = (
  questionAttemptData: Partial<QuestionAnswer>,
  params?: Record<string, number>,
): AnyAction =>
  action(QuestionnairesTypes.POST_QUESTION_ANSWER_REQUEST, {
    questionAttemptData,
    params,
  });

export const postQuestionAnswerSuccess = (
  data: AdminApiV1QuestionAttemptsPostRequest,
): AnyAction => {
  return action(QuestionnairesTypes.POST_QUESTION_ANSWER_SUCCESS, {
    data,
  });
};

export const postQuestionAnswerFailure = (
  data: AdminApiV1QuestionAttemptsPostRequest,
): AnyAction =>
  action(QuestionnairesTypes.POST_QUESTION_ANSWER_FAILURE, {data});

export const fetchQuestionnaireAnswersByStepRequest = (
  stepId: string | number,
  learningPathUserInstanceId?: string | number,
): AnyAction =>
  action(QuestionnairesTypes.FETCH_QUESTIONNAIRE_ANSWERS_BY_STEP_REQUEST, {
    stepId,
    learningPathUserInstanceId,
  });

export const fetchQuestionnaireAnswersByStepSuccess = (
  questionnaireAnswers: QuestionAttempt[],
): AnyAction =>
  action(QuestionnairesTypes.FETCH_QUESTIONNAIRE_ANSWERS_BY_STEP_SUCCESS, {
    questionnaireAnswers,
  });

export const fetchQuestionnaireAnswersByStepFailure = (): AnyAction =>
  action(QuestionnairesTypes.FETCH_QUESTIONNAIRE_ANSWERS_BY_STEP_FAILURE);

export const fetchQuestionnaireAnswersByStudentRequest = (
  studentId: number,
): AnyAction =>
  action(QuestionnairesTypes.FETCH_QUESTIONNAIRE_ANSWERS_BY_STUDENT_REQUEST, {
    studentId,
  });

export const fetchQuestionnaireAnswersByStudentSuccess = (
  questionnaireAnswers: QuestionAttempt[],
): AnyAction =>
  action(QuestionnairesTypes.FETCH_QUESTIONNAIRE_ANSWERS_BY_STUDENT_SUCCESS, {
    questionnaireAnswers,
  });

export const fetchQuestionnaireAnswersByStudentFailure = (): AnyAction =>
  action(QuestionnairesTypes.FETCH_QUESTIONNAIRE_ANSWERS_BY_STUDENT_FAILURE);

export const clearQuestionnaireAnswers = (): AnyAction =>
  action(QuestionnairesTypes.ClEAR_QUESTION_ATTEMPT);

// Sagas
export function* fetchQuestionnaireById(
  action: AnyAction,
): Generator<StrictEffect, void, AxiosResponse<Questionnaire>> {
  try {
    const response = yield call(
      fetchQuestionnaireByIdService,
      action.payload.questionnaireId,
    );

    yield put(fetchQuestionnaireByIdSuccess(response.data));
  } catch (err) {
    yield put(fetchQuestionnaireByIdFailure());
  }
}

export function* postQuestionAnswers(
  action: AnyAction,
): Generator<
  StrictEffect,
  void,
  AxiosResponse<AdminApiV1QuestionAttemptsPostRequest>
> {
  try {
    const response = yield call(
      postQuestionAnswerService,
      action.payload.questionAttemptData,
      action.payload.params,
    );

    yield put(postQuestionAnswerSuccess(response.data));
  } catch (error) {
    const err = error as any;
    yield put(postQuestionAnswerFailure(err?.response?.data));
  }
}

export function* fetchQuestionnaireAnswersByStep(
  action: AnyAction,
): Generator<StrictEffect, void, AxiosResponse<QuestionAttempt[]>> {
  try {
    const response = yield call(
      fetchQuestionnaireAnswersByStepService,
      action.payload.stepId,
      action.payload.learningPathUserInstanceId,
    );

    yield put(fetchQuestionnaireAnswersByStepSuccess(response.data));
  } catch (err) {
    yield put(fetchQuestionnaireAnswersByStepFailure());
  }
}

export function* fetchQuestionnaireAnswersByStudent(
  action: AnyAction,
): Generator<StrictEffect, void, AxiosResponse<QuestionAttempt[]>> {
  try {
    const response = yield call(
      fetchQuestionAnswersByStudentService,
      action.payload.studentId,
    );

    yield put(fetchQuestionnaireAnswersByStudentSuccess(response.data));
  } catch (err) {
    yield put(fetchQuestionnaireAnswersByStudentFailure());
  }
}
// Selectors
const questionnairesDataSelector = (state: ApplicationState) =>
  state.get('questionnaires') as QuestionnairesState;

export const getQuestionnaire = createSelector(
  questionnairesDataSelector,
  (questionnaires) => questionnaires.questionnaire,
);

export const getQuestionnaireAnswers = createSelector(
  questionnairesDataSelector,
  (questionnaires) => questionnaires.questionnaireAnswers,
);

export const getQuestionnaireStatus = createSelector(
  questionnairesDataSelector,
  (questionnaires) => questionnaires.status,
);

// Initial state
export const INITIAL_STATE: QuestionnairesState = {
  questionnaire: {
    id: -1,
    name: '',
    steps: [],
    created: '',
    modified: '',
  },
  questionnaireAnswers: [],
  status: {
    fulfilled: false,
    loading: false,
    error: false,
    posting: false,
    errorMessage: '',
  },
};

// Reducer
export const reducer: Reducer<QuestionnairesState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case QuestionnairesTypes.FETCH_QUESTIONNAIRE_BY_ID_REQUEST:
      return {
        ...state,
        status: {loading: true, error: false, fulfilled: false, posting: false},
      };

    case QuestionnairesTypes.FETCH_QUESTIONNAIRE_BY_ID_SUCCESS:
      return {
        ...state,
        questionnaire: action.payload.data,
        status: {loading: false, error: false, fulfilled: true, posting: false},
      };

    case QuestionnairesTypes.FETCH_QUESTIONNAIRE_BY_ID_FAILURE:
      return {
        ...state,
        status: {loading: false, error: true, fulfilled: false, posting: false},
      };

    case QuestionnairesTypes.POST_QUESTION_ANSWER_REQUEST:
      return {
        ...state,
        status: {loading: false, error: false, fulfilled: false, posting: true},
      };

    case QuestionnairesTypes.POST_QUESTION_ANSWER_SUCCESS:
      return {
        ...state,
        questionnaireAnswers: [
          ...state.questionnaireAnswers,
          action.payload.data,
        ],
        status: {loading: false, error: false, fulfilled: true, posting: false},
      };

    case QuestionnairesTypes.POST_QUESTION_ANSWER_FAILURE:
      return {
        ...state,
        status: {
          loading: false,
          error: true,
          fulfilled: false,
          posting: false,
          errorMessage: action.payload.data?.non_field_errors[0],
        },
      };

    case QuestionnairesTypes.FETCH_QUESTIONNAIRE_ANSWERS_BY_STUDENT_REQUEST:
      return {
        ...state,
        status: {loading: true, error: false, fulfilled: false, posting: false},
      };

    case QuestionnairesTypes.FETCH_QUESTIONNAIRE_ANSWERS_BY_STUDENT_SUCCESS:
      return {
        ...state,
        questionnaireAnswers: action.payload.questionnaireAnswers.results,
        status: {loading: false, error: false, fulfilled: true, posting: false},
      };

    case QuestionnairesTypes.FETCH_QUESTIONNAIRE_ANSWERS_BY_STUDENT_FAILURE:
      return {
        ...state,
        status: {loading: false, error: true, fulfilled: false, posting: false},
      };

    case QuestionnairesTypes.FETCH_QUESTIONNAIRE_ANSWERS_BY_STEP_REQUEST:
      return {
        ...state,
        status: {loading: true, error: false, fulfilled: false, posting: false},
      };

    case QuestionnairesTypes.FETCH_QUESTIONNAIRE_ANSWERS_BY_STEP_SUCCESS:
      return {
        ...state,
        questionnaireAnswers: action.payload.questionnaireAnswers.results,
        status: {loading: false, error: false, fulfilled: true, posting: false},
      };

    case QuestionnairesTypes.FETCH_QUESTIONNAIRE_ANSWERS_BY_STEP_FAILURE:
      return {
        ...state,
        status: {loading: false, error: true, fulfilled: false, posting: false},
      };

    case QuestionnairesTypes.ClEAR_QUESTION_ATTEMPT:
      return {
        ...state,
        questionnaireAnswers: [],
        status: {loading: false, error: false, fulfilled: true, posting: false},
      };
    default:
      return state;
  }
};

export default reducer;
