import api from './api';

export interface WebPush {
  p256dh?: string;
  auth?: string;
  registration_id?: string;
  browser?: string;
}

export const webPushService = (data: WebPush) =>
  api(`/api/v1/web_push/`, {
    method: 'post',
    data,
  });
