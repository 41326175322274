import {CSSProperties} from 'react';
import styles from './TooltipOverlay.module.scss';

export interface SimpleTooltipOverlayProps {
  className?: string;
  style?: CSSProperties;
  competence?: string;
  score?: string | number;
  entity?: any;
}

const SimpleTooltipOverlay: React.FC<SimpleTooltipOverlayProps> = ({
  className,
  style,
  competence,
  score,
  children,
}) => {
  return (
    <div
      className={`${styles.tooltipOverlay} ${className || ''}`}
      style={style}
    >
      {competence && (
        <div className={styles.titleWrapper}>
          <span>{competence}</span>
          <p>{score}</p>
        </div>
      )}

      <div className={styles.tooltipBody}>{children}</div>
    </div>
  );
};

export default SimpleTooltipOverlay;
