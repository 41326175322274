import {AxiosResponse} from 'axios';
import api from './api';

export interface ActivitySyncData {
  id?: number;
  instruction_id_id: number;
  test_id: number;
  step_id?: number;
  composition_raw?: string;
  title?: string;
  composition_source?: string;
  flag_finished?: true;
  finished?: string;
  status?: string;
  review_type?: string;
  created?: string;
  modified?: string;
  write_preparation?: {
    id: number;
    aids_markup_html: string;
    annotations: string;
    markup_html_count: number;
    created_at: string;
    modified_at: string;
  };
  user_feelings?: {
    id: number;
    choice: string;
    comment: string;
    stage: string;
    created_at: string;
    modified_at: string;
  }[];
}

export const APIActivitySyncService = (
  data: ActivitySyncData,
  params?: Record<string, string>,
): Promise<AxiosResponse<any>> =>
  api(`/api/v1/sync_composition/`, {
    method: 'post',
    params,
    data,
  });
