import {TokenResponse} from '@react-oauth/google';
import api from './api';

export type GoogleCredentials = Omit<
  TokenResponse,
  'error' | 'error_description' | 'error_uri'
>;

export const googleService = (googleCredentials: GoogleCredentials) =>
  api(`/api/v1/google/auth/`, {
    method: 'post',
    data: {
      grant_type: 'convert_token',
      client_id: process.env.REACT_APP_SSO_CLIENT_ID,
      client_secret: process.env.REACT_APP_SSO_CLIENT_SECRET,
      backend: 'google-oauth2',
      token: googleCredentials.access_token,
      platform: 'student',
    },
  });
