import {
  AdminApiV1QuestionAttemptsGetResponse200,
  AdminQuestionAttempt,
  Questionnaire,
} from '@letrustech/letrus-api-interfaces/dist/interfaces/letrus_api';
import {AxiosResponse} from 'axios';
import {QuestionAnswer} from 'features/useQuestionnaire';
import api from './api';

export const fetchQuestionnaireByIdService = (
  questionnaireId: number,
): Promise<AxiosResponse<Questionnaire>> =>
  api(`/api/v1/questionnaires/${questionnaireId}/`);

export const postQuestionAnswerService = (
  data: Partial<QuestionAnswer>,
  params?: Record<string, number>,
): Promise<AxiosResponse<AdminQuestionAttempt>> =>
  api(`/api/v1/question_attempts/`, {
    method: 'post',
    params,
    data,
  });

export const fetchQuestionnaireAnswersByStepService = (
  stepId: number | string,
  learningPathUserInstanceId?: number | string,
): Promise<AxiosResponse<AdminApiV1QuestionAttemptsGetResponse200>> =>
  api(`/api/v1/question_attempts/`, {
    params: {
      step: stepId,
      learning_path_user_instance: learningPathUserInstanceId,
    },
  });

export const fetchQuestionAnswersByStudentService = (
  studentId: number,
): Promise<AxiosResponse<AdminApiV1QuestionAttemptsGetResponse200>> =>
  api(`/api/v1/question_attempts/`, {
    params: {
      user: studentId,
    },
  });
