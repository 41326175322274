import {takeLatest} from 'redux-saga/effects';
import {
  createStudentParent,
  fetchCurrentUser,
  logout,
  refreshJwtToken,
  resetPassword,
  sendUserOpinionForSurveys,
  updateOrCreateProfile,
  updateProfile,
  updateStudentParent,
  updateStudentProfile,
  updateUser,
  UserTypes,
  validateAccessCode,
} from '../reducers/user';

const userSagas = [
  takeLatest(UserTypes.CREATE_STUDENT_PARENT_REQUEST, createStudentParent),
  takeLatest(UserTypes.FETCH_CURRENT_USER_REQUEST, fetchCurrentUser),
  takeLatest(UserTypes.LOGOUT_REQUEST, logout),
  takeLatest(UserTypes.REFRESH_JWT_TOKEN_REQUEST, refreshJwtToken),
  takeLatest(UserTypes.RESET_PASSWORD_REQUEST, resetPassword),
  takeLatest(
    UserTypes.SEND_USER_OPINION_FOR_SURVEYS_REQUEST,
    sendUserOpinionForSurveys,
  ),
  takeLatest(UserTypes.UPDATE_STUDENT_PROFILE_REQUEST, updateStudentProfile),
  takeLatest(UserTypes.UPDATE_STUDENT_PARENT_REQUEST, updateStudentParent),
  takeLatest(UserTypes.UPDATE_PROFILE_REQUEST, updateProfile),
  takeLatest(UserTypes.UPDATE_REQUEST, updateUser),
  takeLatest(UserTypes.UPDATE_OR_CREATE_PROFILE_REQUEST, updateOrCreateProfile),
  takeLatest(UserTypes.VALIDATE_ACCESS_CODE_REQUEST, validateAccessCode),
];

export default userSagas;
